import { CustomCellRendererProps } from 'ag-grid-react';
import { useFetchStores } from '../../../hooks/Admin';
import { EReduxAsyncState } from '../../../types/redux';
import { Button, Modal, Space, Spin, Typography } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { groupStoresByRestrictedStatusPerProduct } from '../../../utils';
import { CustomTextCell } from '../../Table/CustomComponents';
import { ClearIcon, EyeIcon, TaskIcon } from '../../Icons';
import { StoreCardBase } from '../../Admin';
import { IProduct } from '../../../types';
import { IStore } from '../../../types';
import { Virtuoso } from 'react-virtuoso';
import { useDispatch } from 'react-redux';
import { ProductActions } from '../../../redux/actions';
import { useAppSelector } from '../../../redux/hooks';

export const CustomCellStores = (props: CustomCellRendererProps<IProduct>) => {
  const dispatch = useDispatch();
  const updateProducts = useAppSelector(state => state.Product.updateProducts);
  const stores = useFetchStores();
  const [openModal, setOpenModal] = useState(false);
  const [addRestrictStores, setAddRestrictStores] = useState<IStore[]>([]);
  const [removeRestrictStores, setRemoveRestrictStores] = useState<IStore[]>([]);
  const [nonRestrictStores, setNonRestrictStores] = useState<IStore[]>([]);
  const [restrictStores, setRestrictStores] = useState<IStore[]>([]);

  const currentProduct = props.data;
  const restrictedBopisLocationsCode = useMemo(() => {
    return props.data?.restrictedBopisLocations?.value
      ? (JSON.parse(props.data?.restrictedBopisLocations?.value) as string[])
      : [];
  }, [props.data?.restrictedBopisLocations?.value]);

  const handleRemoveRestrictClick = (store: IStore) => {
    if (restrictedBopisLocationsCode.includes(store.locationCode)) {
      setRemoveRestrictStores(prev => [store, ...prev]);
    }

    setNonRestrictStores(prev => [store, ...prev]);
    setAddRestrictStores(prev => prev.filter(value => value.locationCode !== store.locationCode));
    setRestrictStores(prev => prev.filter(value => value.locationCode !== store.locationCode));
  };
  const handleAddRestrictClick = (store: IStore) => {
    setRestrictStores(prev => [store, ...prev]);
    if (!restrictedBopisLocationsCode.includes(store.locationCode)) {
      setAddRestrictStores(prev => [store, ...prev]);
    }
    setRemoveRestrictStores(prev =>
      prev.filter(value => value.locationCode !== store.locationCode),
    );
    setNonRestrictStores(prev => prev.filter(value => value.locationCode !== store.locationCode));
  };

  const handleCancel = (closeModal?: boolean) => {
    setAddRestrictStores([]);
    setRemoveRestrictStores([]);
    if (stores.data && currentProduct) {
      const data = groupStoresByRestrictedStatusPerProduct(stores.data, currentProduct);
      setNonRestrictStores(data.nonRestrictStores);
      setRestrictStores(data.restrictStores);
    }
    if (closeModal) {
      setOpenModal(false);
    }
  };

  const handleConfirmClick = () => {
    if (!props.data?.id) {
      return;
    }
    if (addRestrictStores.length || removeRestrictStores.length) {
      dispatch(
        ProductActions.updateProducts.saga({
          actionType: 'setRestrictedStores',
          updatePayload: {
            shopifyId: props.data?.id,
            addLocationCodes: addRestrictStores.map(store => store.locationCode),
            removeLocationCode: removeRestrictStores.map(store => store.locationCode),
          },
        }),
      );
    }
  };

  useEffect(() => {
    if (stores.data && currentProduct) {
      const data = groupStoresByRestrictedStatusPerProduct(stores.data, currentProduct);
      setNonRestrictStores(data.nonRestrictStores);
      setRestrictStores(data.restrictStores);
    }
  }, [currentProduct]);

  useEffect(() => {
    if (updateProducts.status === EReduxAsyncState.success) {
      handleCancel();
    }
  }, [updateProducts.status]);

  if (stores.status === EReduxAsyncState.inProgress) {
    return <Spin size="small" />;
  }

  return (
    <>
      <CustomTextCell
        {...props}
        showValue={true}
        endComponent={
          props.value ? (
            <Button onClick={() => setOpenModal(true)} icon={<EyeIcon />} type="text" />
          ) : null
        }
      />
      <Modal
        width={'100%'}
        centered
        title={`BOPIS stores for ${props.data?.itemCode?.value || ''}: ${props.data?.title || ''}`}
        open={openModal}
        onCancel={() => handleCancel(true)}
        footer={null}
        className=" max-w-5xl h-[80%] overflow-hidden relative [&_>_div:has(.ant-modal-content)]:h-full"
        classNames={{
          wrapper: '',
          content: 'absolute w-full h-full  flex flex-col  overflow-hidden',
          body: ' h-full overflow-hidden w-full',
          header: 'border-0 !border-b !border-solid !border-colorBorder !mb-0 !pb-2 ',
        }}
      >
        <div className="w-full h-full flex flex-col overflow-hidden gap-y-4">
          <div className="w-full h-full flex  flex-row  overflow-hidden">
            <div className=" basis-1/2 flex flex-col h-full border-solid border-0 border-r-[0.5px] border-colorBorder overflow-hidden gap-y-3 pt-3">
              <div className="pr-3">
                <Typography.Text>{`BOPIS restricted at ${restrictStores.length} ${restrictStores.length > 1 ? 'stores' : 'store'}`}</Typography.Text>
              </div>
              <Virtuoso
                style={{ height: '100%' }}
                className=" [&_>_div]:pr-3"
                data={restrictStores}
                itemContent={(index, store) => (
                  <StoreCardBase key={store.sys.id} store={store} className=" mb-3">
                    <div className=" flex justify-end items-center">
                      <Button
                        disabled={updateProducts.status === EReduxAsyncState.inProgress}
                        type="primary"
                        shape="round"
                        onClick={() => handleRemoveRestrictClick(store)}
                      >
                        Enable
                      </Button>
                    </div>
                  </StoreCardBase>
                )}
              />
            </div>
            <div className=" basis-1/2 flex flex-col h-full border-solid border-0 border-l-[0.5px] border-colorBorder overflow-hidden gap-y-3 pt-3">
              <div className="pl-3">
                <Typography.Text>{`BOPIS enabled at ${nonRestrictStores.length} ${nonRestrictStores.length > 1 ? 'stores' : 'store'}`}</Typography.Text>
              </div>
              <Virtuoso
                style={{ height: '100%' }}
                className=" [&_>_div]:pl-3"
                data={nonRestrictStores}
                itemContent={(index, store) => (
                  <StoreCardBase key={store.sys.id} store={store} className=" mb-3">
                    <div className=" flex justify-end items-center">
                      <Button
                        disabled={updateProducts.status === EReduxAsyncState.inProgress}
                        type="primary"
                        shape="round"
                        onClick={() => handleAddRestrictClick(store)}
                      >
                        Restrict
                      </Button>
                    </div>
                  </StoreCardBase>
                )}
              />
            </div>
          </div>
          <div className="flex justify-end items-center">
            <Space>
              <Button
                className="flex justify-center items-center"
                disabled={updateProducts.status === EReduxAsyncState.inProgress}
                icon={<ClearIcon />}
                shape="round"
                onClick={() => handleCancel(true)}
              >
                Cancel
              </Button>
              <Button
                className="flex justify-center items-center"
                disabled={
                  addRestrictStores.length + removeRestrictStores.length < 1 ||
                  updateProducts.status === EReduxAsyncState.inProgress
                }
                icon={<TaskIcon />}
                type="primary"
                shape="round"
                onClick={handleConfirmClick}
                loading={updateProducts.status === EReduxAsyncState.inProgress}
              >
                Confirm
              </Button>
            </Space>
          </div>
        </div>
      </Modal>
    </>
  );
};
