import * as Realm from 'realm-web';
import atlasConfig from './atlasConfig.json';
import { EMongoDbCollection } from './types/mongodb';

export const realmApp = new Realm.App({ id: atlasConfig.appId, baseUrl: atlasConfig.baseUrl });

export function getCollection<T extends { _id: string } = any>(
  collection: `${EMongoDbCollection}`,
) {
  if (!realmApp.currentUser) {
    throw new Error('Login Error');
  }

  const mdb = realmApp.currentUser.mongoClient('mongodb-atlas');
  return mdb.db(atlasConfig.databaseName).collection<T>(collection);
}
