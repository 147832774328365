import { z } from 'zod';
import { StringToDateSchema } from '../common';

export const MongodbUserSchema = z.object({
  _id: z.string(), // user email
  userId: z.string(),
  name: z.string(),
  email: z.string(),
  storeAccess: z
    .array(
      z.object({
        locationId: z.string(),
        roles: z.array(z.string()).optional(),
      }),
    )
    .optional(), // Location Id
  oneLoginRoles: z.array(z.string()).optional(),
  createdOn: StringToDateSchema,
  serverCreatedOn: StringToDateSchema,
  updatedOn: StringToDateSchema,
  serverUpdatedOn: StringToDateSchema,
  enabled: z.boolean().optional(),
  removedOn: StringToDateSchema,
});

export type IMongodbUser = z.infer<typeof MongodbUserSchema>;
