import { EAppErrorCode } from '../types';
import map from 'lodash/map';
import { realmApp } from '../realm';
import { REALM_ACCESS_TOKEN_HEADER_TEXT } from '../constants/common';

export const getSingularOrPluralString = (
  count: number,
  singularText: string,
  pluralText: string,
) => {
  if (count > 1) {
    return `${count} ${pluralText}`;
  } else if (count === 0) {
    return `No ${pluralText}`;
  }
  return `${count} ${singularText}`;
};

export class AppError extends Error {
  status: number;
  code: `${EAppErrorCode}` = EAppErrorCode.SOMETHING_WENT_WRONG;
  constructor(code: `${EAppErrorCode}`, message: string = '', status?: number) {
    super(message);
    this.code = code;
    this.status = status || 500;
    this.name = 'AppError';
  }
}

export const convertArrayToMap = <T>(arr: Array<T>, key: keyof T): Map<string, T> => {
  const newMap: Map<string, T> = new Map(map(arr, (obj: any) => [String(obj[key]), obj]));
  return newMap;
};

export function formatDate(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  return new Intl.DateTimeFormat('en-US', options).format(date);
}
