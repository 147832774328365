import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { CompanyActions } from '../actions';

import { ActionType } from 'typesafe-actions';
import { callPageFunction } from '../../utils';

export function* getCompanySaga(
  action: ActionType<typeof CompanyActions.getCompanySettings.saga>,
): Generator<unknown, void, unknown> {
  try {
    yield put(CompanyActions.getCompanySettings.start());
    const companySettings = yield call(callPageFunction, '/api/private/mongodb/companySettings');
    yield put(CompanyActions.setCompanySettings(companySettings));
    yield put(CompanyActions.getCompanySettings.success());
  } catch (error: unknown) {
    console.log('error', error);
    CompanyActions.getCompanySettings.error(error as Error);
  }
}

export function* addCompanySettingsSaga(
  action: ActionType<typeof CompanyActions.addCompanySettings.saga>,
): Generator<unknown, void, unknown> {
  try {
    yield put(CompanyActions.addCompanySettings.start());

    const companySettings = action.payload;

    const updatedCompanySettings = yield call(
      callPageFunction,
      '/api/private/mongodb/addCompanySettings',
      {
        body: companySettings as any,
      },
    );

    yield put(CompanyActions.setCompanySettings(updatedCompanySettings));
    yield put(CompanyActions.addCompanySettings.success());
  } catch (error: unknown) {
    CompanyActions.addCompanySettings.error(error as Error);
  }
}

function* getCompanySettingListener() {
  yield takeLatest(CompanyActions.getCompanySettings.saga.toString(), getCompanySaga);
}

function* addCompanySettingListener() {
  yield takeLatest(CompanyActions.addCompanySettings.saga.toString(), addCompanySettingsSaga);
}

export default function* companySaga() {
  yield fork(getCompanySettingListener);
  yield fork(addCompanySettingListener);
}
