import { useEffect, useMemo, useRef, useState } from 'react';
import { Table, ITableProps } from '../../Table';
import { ColDef } from 'ag-grid-community';
import { IShopifyProduct } from '../../../types/shopify';
import { IProduct, RouteNames } from '../../../types';
import { AgGridReact } from 'ag-grid-react';
import { ProductTableColDef } from './getProductsTableColDef';
import { useFetchStores } from '../../../hooks/Admin';
import {
  getProductsWithSamePriceAndCategory,
  groupStoresByRestrictedStatusPerProduct,
} from '../../../utils';
import { useMatch } from 'react-router-dom';

interface IProductsTableProps {
  rowData: IProduct[];
}

export const ProductsTable = (props: IProductsTableProps & Partial<ITableProps>) => {
  const { rowData, tableRef, ...rest } = props;
  const isAdminSettingsProducts = useMatch(RouteNames.AdminSettingsProducts);
  // const isAdminSettingsStoresDetails = useMatch(RouteNames.AdminSettingsStoresDetails);
  const stores = useFetchStores();

  const gridRef = useRef<AgGridReact>(null);

  useEffect(() => {
    if (tableRef) {
      tableRef.current = gridRef.current;
    }
  }, [gridRef.current]);

  return (
    <Table
      {...rest}
      tableRef={gridRef}
      suppressRowClickSelection
      rowData={rowData}
      rowSelection="multiple"
      pagination
      paginationPageSizeSelector={[25, 50]}
      paginationPageSize={50}
      getRowId={params => params.data?.id}
    />
  );
};
