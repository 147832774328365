import { actionCreator, asyncActionCreator } from '../../redux/helper';
import { IShopifyProduct } from '../../types/shopify';
import { FunctionProductActionType, IFunctionProductAction } from '../../../server/products';

enum ProductActionTypes {
  FETCH_PRODUCTS = 'PRODUCT/FETCH_PRODUCTS',
  UPDATE_PRODUCTS = 'PRODUCT/UPDATE_PRODUCTS',
  SET_PRODUCTS = 'PRODUCT/SET_PRODUCTS',
}

export const fetchProducts = asyncActionCreator<IShopifyProduct[]>(
  ProductActionTypes.FETCH_PRODUCTS,
);

interface UpdateProductsSagaPayload<
  T extends FunctionProductActionType = FunctionProductActionType,
> {
  actionType: T;
  updatePayload: Parameters<IFunctionProductAction[T]>[1];
}

export const updateProducts = asyncActionCreator<undefined, UpdateProductsSagaPayload>(
  ProductActionTypes.UPDATE_PRODUCTS,
);

export const setProducts = actionCreator<IShopifyProduct[]>(ProductActionTypes.SET_PRODUCTS);
