import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { ProductActions } from '../../redux/actions';

export const useFetchProducts = () => {
  const dispatch = useAppDispatch();
  const products = useAppSelector(state => state.Product.products);

  useEffect(() => {
    if (!products.data?.length) {
      dispatch(ProductActions.fetchProducts.saga());
    }
  }, []);

  return products;
};
