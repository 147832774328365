import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { AppState } from '../reducers/rootReducer';
import { ONE_LOGIN_ROLES } from '../../constants';

const usersState = (state: AppState) => state.User;

export const allUsers = createDraftSafeSelector(
  usersState,
  state => state.users?.data?.filter(user => !Boolean(user.removedOn)) ?? [],
);

export const adminUsers = createDraftSafeSelector(allUsers, users =>
  users.filter(user => user.oneLoginRoles?.includes(ONE_LOGIN_ROLES.phantom_bopis_admin)),
);

export const fulfillmentUsers = createDraftSafeSelector(allUsers, users =>
  users.filter(
    user =>
      user.oneLoginRoles?.includes(ONE_LOGIN_ROLES.phantom_bopis_fulfillment) &&
      !user.oneLoginRoles?.includes(ONE_LOGIN_ROLES.phantom_bopis_admin),
  ),
);
