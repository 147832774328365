import _groupBy from 'lodash/groupBy';
import { IStore } from '../types';
import dayjs from 'dayjs';
import { IMongodbStore, EMongodbStoreBopisStatus } from '../types/mongodb';
import { IContentfulLocation } from '../types/contentful';
import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import { IShopifyProduct } from '../types/shopify';

interface IMergeMongodbAndContentfulStoresOption {
  mongodbStores: IMongodbStore[];
  contentfulStores: IContentfulLocation[];
}

export const mergeMongodbAndContentfulStores = ({
  mongodbStores = [],
  contentfulStores = [],
}: IMergeMongodbAndContentfulStoresOption) => {
  const mongodbStoresKeyByLocationCode = keyBy(mongodbStores, data => data.locationCode);

  return contentfulStores.reduce((_stores, store) => {
    if (store.locationCode) {
      const mongodbStore = mongodbStoresKeyByLocationCode[store.locationCode] || {};
      _stores.push({
        ...store,
        ...mongodbStore,
      });
    }
    return _stores;
  }, [] as IStore[]);
};

export const getDirectionUrl = (location: IStore) => {
  return `https://www.google.com/maps/dir/Current+Location/${location.addressStreet},${location.addressCity},${location.addressState},${location.addressZip}`;
};

export const isStoreBopisIsActive = (store: IStore) => {
  const beginDate = store.bopisSettings?.bopisBeginDate
    ? dayjs(store.bopisSettings?.bopisBeginDate)
    : null;
  const endDate = store.bopisSettings?.bopisEndDate
    ? dayjs(store.bopisSettings?.bopisEndDate)
    : null;

  if (store.bopisStatus === 'Active' && !beginDate && !endDate) {
    return true;
  }

  if (
    store.bopisStatus === 'Active' &&
    beginDate &&
    beginDate.isBefore(dayjs()) &&
    (!endDate || endDate.isAfter(dayjs()))
  ) {
    return true;
  }

  return false;
};

export const isStoreBopisIsSchedule = (store: IStore) => {
  const beginDate = store.bopisSettings?.bopisBeginDate
    ? dayjs(store.bopisSettings?.bopisBeginDate)
    : null;

  if (store.bopisStatus === 'Active' && beginDate && beginDate.isAfter(dayjs())) {
    return true;
  }
  return false;
};

export const isStoreBopisIsInactive = (store: IStore) => {
  const endDate = store.bopisSettings?.bopisEndDate
    ? dayjs(store.bopisSettings?.bopisEndDate)
    : null;

  if (store.bopisStatus === 'Inactive') {
    return true;
  }
  if (store.bopisStatus === 'Active' && endDate && endDate.isBefore(dayjs())) {
    return true;
  }
  return false;
};

export enum EStoreBopisStatus {
  Active = 'Active',
  Scheduled = 'Scheduled',
  Inactive = 'Inactive',
  NoBopis = 'No Bopis',
}
export const getStoreBopisStatus = (store: IStore) => {
  if (isStoreBopisIsActive(store) === true) return EStoreBopisStatus.Active;

  if (isStoreBopisIsSchedule(store) === true) return EStoreBopisStatus.Scheduled;

  if (isStoreBopisIsInactive(store) === true) return EStoreBopisStatus.Inactive;

  return EStoreBopisStatus.NoBopis;
};

interface IGroupStoresOnBopisStatus {
  active?: IStore[];
  inactive?: IStore[];
  scheduled?: IStore[];
  noBopis?: IStore[];
}

export const groupStoresOnBopisStatus = (stores: IStore[]): IGroupStoresOnBopisStatus => {
  const storesGroup = _groupBy(stores || [], store => {
    if (!store.bopisStatus || store.bopisStatus === EMongodbStoreBopisStatus.NoBopis) {
      return 'noBopis';
    }

    if (isStoreBopisIsActive(store)) {
      return 'active';
    }

    if (isStoreBopisIsSchedule(store)) {
      return 'scheduled';
    }

    if (isStoreBopisIsInactive(store)) {
      return 'inactive';
    }
  });

  return storesGroup as any;
};

interface IGroupStoresOnBopisState {
  bopis?: IStore[];
  noBopis?: IStore[];
}

export const groupStoresOnBopisState = (stores: IStore[]): IGroupStoresOnBopisState => {
  const storesGroup = _groupBy(stores || [], store => {
    if (!store.bopisStatus || store.bopisStatus === EMongodbStoreBopisStatus.NoBopis) {
      return 'noBopis';
    }

    return 'bopis';
  });

  if (storesGroup.bopis?.length) {
    storesGroup.bopis.sort(function compare(a, b) {
      var dateA = +new Date(a.serverUpdatedOn!);
      var dateB = +new Date(b.serverUpdatedOn!);
      return dateB - dateA;
    });
  }

  return storesGroup as any;
};

export const getStoreCountByStatus = (stores: IStore[]) => {
  const _stores = groupStoresOnBopisStatus(stores || []);
  return {
    active: _stores?.active?.length || 0,
    inactive: _stores?.inactive?.length || 0,
    scheduled: _stores?.scheduled?.length || 0,
    noBopis: _stores?.noBopis?.length || 0,
  };
};

export const groupStoresByRestrictedStatusPerProduct = (
  stores: IStore[],
  product: IShopifyProduct,
) => {
  let nonRestrictStores: IStore[] = [];
  let restrictStores: IStore[] = [];
  const storesGroupByBopisStatus = groupStoresOnBopisStatus(stores);
  const restrictedBopisLocations = product.restrictedBopisLocations?.value
    ? (JSON.parse(product?.restrictedBopisLocations?.value) as string[])
    : [];

  const bopisStores = [
    ...(storesGroupByBopisStatus.active || []),
    ...(storesGroupByBopisStatus.scheduled || []),
  ];

  if (bopisStores.length) {
    nonRestrictStores = bopisStores.filter(
      store => !restrictedBopisLocations.includes(store.locationCode),
    );
    restrictStores = bopisStores.filter(store =>
      restrictedBopisLocations.includes(store.locationCode),
    );
  }

  return {
    nonRestrictStores,
    restrictStores,
  };
};
