import { CustomCellRendererProps } from 'ag-grid-react';

export interface ICustomTextCellProps {
  startComponent?: React.ReactNode;
  endComponent?: React.ReactNode;
  onEndComponentClick?: (params: CustomCellRendererProps) => void;
  onStartComponentClick?: (params: CustomCellRendererProps) => void;
  className?: string;
  showValue?: boolean;
}

export const CustomTextCell = ({
  endComponent,
  startComponent,
  onEndComponentClick,
  onStartComponentClick,
  className,
  showValue,
  ...rest
}: ICustomTextCellProps & CustomCellRendererProps) => {
  return (
    <div className="flex w-full h-full justify-between items-center gap-x-2">
      {startComponent && (
        <div
          onClick={() => onStartComponentClick?.(rest)}
          className="h-full flex justify-center items-center"
        >
          {startComponent}
        </div>
      )}

      {showValue && <div className="text-ellipsis overflow-hidden w-full">{rest.value}</div>}

      {endComponent && (
        <div
          onClick={() => onEndComponentClick?.(rest)}
          className="h-full flex justify-center items-center"
        >
          {endComponent}
        </div>
      )}
    </div>
  );
};
