import { ValidateUser } from './components';
import { AppRoutes } from './routes';

import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';

import { LicenseManager } from 'ag-grid-enterprise';

const agGridKey = process.env.REACT_APP_AG_GRID_KEY as string;

LicenseManager.setLicenseKey(agGridKey);

function App() {
  return (
    <ValidateUser>
      <AppRoutes />
    </ValidateUser>
  );
}

export default App;
