import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { StoreActions } from '../../redux/actions';

export const useFetchStores = () => {
  const dispatch = useAppDispatch();
  const stores = useAppSelector(state => state.Store.stores);

  useEffect(() => {
    if (!stores.data?.length) {
      dispatch(StoreActions.fetchStores.saga());
    }
  }, []);

  return stores;
};
