import { useNavigation, useFetchers, useRevalidator } from 'react-router-dom';
import { useMemo } from 'react';

/**
 * This is a helper hook that returns the state of every fetcher active on
 * the app and combine it with the state of the global transition and
 * revalidator.
 * @example
 * let states = useGlobalNavigationState();
 * if (state.includes("loading")) {
 *   // The app is loading or revalidating.
 * }
 * if (state.includes("submitting")) {
 *   // The app is submitting.
 * }
 * // The app is idle
 */
export function useGlobalNavigationState() {
  let { state: navigationState } = useNavigation();
  let { state: revalidatorState } = useRevalidator();
  let fetchers = useFetchers();

  /**
   * This gets the state of every fetcher active on the app and combine it with
   * the state of the global transition (Link and Form) and revalidator.
   */
  return useMemo(
    function getGlobalNavigationState() {
      return [
        navigationState,
        // The type cast here is used to remove RevalidatorState from the union
        revalidatorState as 'idle' | 'loading',
        ...fetchers.map(fetcher => fetcher.state),
      ];
    },
    [navigationState, revalidatorState, fetchers],
  );
}
