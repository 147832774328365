import { Switch } from 'antd';
import { CustomCellRendererProps } from 'ag-grid-react';
import { useDispatch } from 'react-redux';
import { ProductActions } from '../../../redux/actions';

export const CustomCellBopis = (props: CustomCellRendererProps) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    props.setValue?.(!props.value);
    dispatch(
      ProductActions.updateProducts.saga({
        actionType: 'updateBopisStatus',
        updatePayload: {
          products: [
            {
              shopifyId: props.data.id,
              bopisStatus: !props.value,
            },
          ],
        },
      }),
    );
  };

  return <Switch checked={props.value} onClick={handleClick} />;
};
