import { realmApp } from '../../realm';
import * as Realm from 'realm-web';
import { IMongodbUser, EMongoDbFunction } from '../../types/mongodb';
import { IOneLoginTokenData } from '../../types';

export const getMongodbUserLatestData = async () => {
  try {
    if (realmApp?.currentUser && realmApp?.currentUser.isLoggedIn) {
      await realmApp.currentUser.refreshCustomData();
      if (realmApp?.currentUser.isLoggedIn) {
        return realmApp?.currentUser.customData as IMongodbUser;
      }
    }
  } catch (e) {
    throw e;
  }
};

export const logoutAccount = async () => {
  try {
    const currentUser = realmApp.currentUser;
    if (currentUser) {
      await currentUser.logOut();
      await realmApp.removeUser(currentUser);
    }
  } catch (e) {
    throw e;
  }
};

export interface ILoginAccountPayload {
  code: string;
}

export const loginAccount = async ({ code }: ILoginAccountPayload) => {
  try {
    const res = await fetch('/oauth/verify', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code,
      }),
    });
    const data = (await res.json()) as IOneLoginTokenData;
    const credentials = Realm.Credentials.jwt(data.id_token);
    const user = await realmApp.logIn(credentials);
    await user.functions.callFunction(EMongoDbFunction.upsertUserDocsOnLogin);
    return await getMongodbUserLatestData();
  } catch (e) {
    await logoutAccount();
    throw e;
  }
};
