import { callPageFunction } from '../../utils';
import { IShopifyProduct } from '../../types/shopify';
import { FunctionProductActionType, IFunctionProductAction } from '../../../server/products';

export const fetchProducts = async () => {
  const products = await callPageFunction('/api/private/products');
  return products as IShopifyProduct[];
};

export const updateProducts = async <T extends FunctionProductActionType>({
  actionType,
  payload,
}: {
  actionType: T;
  payload: Parameters<IFunctionProductAction[T]>[1];
}) => {
  const products = await callPageFunction('/api/private/products/action', {
    body: {
      actionType,
      payload,
    },
  });

  if (Array.isArray(products)) {
    return products as IShopifyProduct[];
  }

  return [products] as IShopifyProduct[];
};
