import { all } from 'redux-saga/effects';
import authSaga from './authSaga';
import orderSaga from './orderSaga';
import userSaga from './userSaga';
import storeSaga from './storeSaga';
import productSaga from './productSaga';
import companySaga from './companySaga';

export function* rootSaga() {
  yield all([authSaga(), orderSaga(), storeSaga(), productSaga(), userSaga(), companySaga()]);
}
