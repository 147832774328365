import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { AuthActions } from '../../redux/actions';
import { Spin } from 'antd';
import { EReduxAsyncState } from '../../types/redux';
import { realmApp } from '../../realm';

export const ValidateUser = (props: React.PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const validateUser = useAppSelector(state => state.Auth.validateUser);
  const currentUser = useAppSelector(state => state.Auth.currentUser);

  useEffect(() => {
    if (!currentUser && validateUser.status === EReduxAsyncState.inProgress) {
      dispatch(AuthActions.validateUser.saga());
    }
  }, []);

  if (
    validateUser.status === EReduxAsyncState.idle ||
    validateUser.status === EReduxAsyncState.success
  ) {
    return <>{props.children}</>;
  } else {
    return <Spin size="large" fullscreen />;
  }
};
