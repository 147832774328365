import { ThemeConfig, theme } from 'antd';

export const themeConfig: ThemeConfig = {
  token: {
    colorBgBase: '#141414',
    colorError: '#FF4040',
    colorInfo: '#985CA6',
    colorLink: '#985CA6',
    colorPrimary: '#985CA6',
    colorSuccess: '#198038',
    colorTextBase: '#FFFFFF',
    colorWarning: '#FF9700',
    fontSize: 16,
    fontSizeSM: 14,
    colorBgContainer: '#282128',
  },
  components: {
    Select: {
      // optionFontSize: 18,
      optionPadding: '8px 10px',
    },
    Dropdown: {},
    Modal: {
      contentBg: '#282128',
      headerBg: '#282128',
      // titleFontSize: 24,
    },

    // InputNumber: {
    //   colorText: '#181818',
    // },
  },
  algorithm: theme.darkAlgorithm,
};
