import { DropletIcon } from '../../Icons';
import clsx from 'clsx';

interface IStorePositionProps {
  position: number;
  className?: string;
}

export const StorePosition = ({ position, className }: IStorePositionProps) => {
  return (
    <div className={clsx('relative rounded-full flex justify-center items-center', className)}>
      <DropletIcon className=" text-colorPrimary rotate-180" size={35} />
      <span className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-60%]  text-colorTextBase  z-[200] text-xs">
        {position}
      </span>
    </div>
  );
};
