import { IMongodbUser } from '../../types/mongodb';
import { actionCreator, asyncActionCreator } from '../helper/index';

enum UserActionTypes {
  GET_USERS = 'USERS/GET_USERS',
  SET_USER = 'USERS/SET_USER',
  UPDATE_USER = 'USERS/UPDATE_USER',
  WATCH_USERS = 'USERS/WATCH_USERS',
}

export const getUsers = asyncActionCreator<undefined, undefined>(UserActionTypes.GET_USERS);

export const updateUser = asyncActionCreator<undefined, Partial<IMongodbUser>>(
  UserActionTypes.UPDATE_USER,
);

export const setUsers = actionCreator<IMongodbUser[]>(UserActionTypes.SET_USER);

export const watchUser = actionCreator<undefined>(UserActionTypes.WATCH_USERS);
