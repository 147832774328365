import { CustomCellRendererProps } from 'ag-grid-react';
import { IProduct } from '../../../types';
import { CustomTextCell } from '../../Table/CustomComponents';
import { Button, Modal } from 'antd';
import { EyeIcon } from '../../Icons';
import { useState } from 'react';
import { ProductCard } from '../ProductCard';

export const CustomCellItemTitle = (props: CustomCellRendererProps<IProduct>) => {
  const [openModal, setOpenModal] = useState(false);
  const handleClick = () => {
    setOpenModal(true);
  };
  return (
    <>
      <CustomTextCell
        showValue
        endComponent={<Button onClick={handleClick} icon={<EyeIcon />} type="text" />}
        {...props}
      />
      {props.data && (
        <Modal
          // title={`${props.data.title} Details`}
          open={openModal}
          centered
          closeIcon={false}
          footer={
            <Button type="primary" shape="round" onClick={() => setOpenModal(false)}>
              Ok
            </Button>
          }
          onCancel={() => setOpenModal(false)}
          width={'100%'}
          className=" max-w-2xl"
          classNames={{
            body: ' pt-5',
          }}
        >
          <ProductCard product={props.data} />
        </Modal>
      )}
    </>
  );
};
