import { REALM_ACCESS_TOKEN_HEADER_TEXT } from '../constants/common';
import { realmApp } from '../realm';

interface CallPageFunctionOptions {
  method?: 'GET' | 'POST';
  body?: string | Record<string, any>;
  headers?: HeadersInit;
  signal?: AbortSignal;
}

export const callPageFunction = async (path: string, options?: CallPageFunctionOptions) => {
  const currentUser = realmApp.currentUser;
  if (currentUser) {
    await currentUser.refreshAccessToken();
  }

  const fetchOptions: any = {
    headers: {
      [REALM_ACCESS_TOKEN_HEADER_TEXT]: currentUser?.accessToken || '',
      'Content-Type': 'application/json',
      ...(options?.headers || {}),
    },
    method: options?.method ? options?.method : options?.body ? 'POST' : 'GET',
    body: JSON.stringify(options?.body),
  };

  if (options?.signal) {
    fetchOptions.signal = options.signal;
  }

  try {
    const result = await fetch(path, fetchOptions);
    const res = await result.json();
    return res;
  } catch (e: any) {
    if (e.name === 'AbortError') {
      console.log('AbortError: Fetch request aborted');
      return;
    }
    console.log('Error calling function: ', e);
    throw e;
  }
};
