import { useMemo } from 'react';
import type { IContentfulStoreHours } from '../../../types/contentful';
import { Space, Typography } from 'antd';
import clsx from 'clsx';

interface IStoreHoursProps {
  storeHours: IContentfulStoreHours;
  className?: string;
}

export function StoreHours({ storeHours, className }: IStoreHoursProps) {
  if (
    storeHours.selectedId === 'everyday' &&
    storeHours?.everyday?.openTime &&
    storeHours?.everyday?.closeTime
  )
    return (
      <Space size={['large', 'small']} className={clsx(className)}>
        <Typography.Text type="secondary">{'Open 7 Days:'}</Typography.Text>
        <Typography.Text type="secondary">
          {`${string12hr(
            storeHours?.everyday?.openTime,
          )}${' '}-${' '}${string12hr(storeHours?.everyday?.closeTime)}`}
        </Typography.Text>
      </Space>
    );

  if (storeHours.selectedId === 'specific') {
    return <WeekDayStoreHours storeHours={storeHours} className={clsx(className)} />;
  }

  return null;
}

function WeekDayStoreHours({
  storeHours,
  className,
}: {
  storeHours: IContentfulStoreHours;
  className?: string;
}) {
  const data = useMemo(() => {
    return Object.entries(storeHours)
      .filter(
        ([_, value]) =>
          value !== 'everyday' &&
          value !== 'specific' &&
          typeof value !== 'string' &&
          value?.openTime &&
          value?.closeTime,
      )
      .map(([key, value]) => ({ key, value }));
  }, [storeHours]);

  return (
    <div className={clsx(className, 'flex flex-col')}>
      {data.map(({ key, value }: { key: string; value: any }) => {
        return (
          <Space size={['large', 'small']} key={key}>
            <Typography.Text type="secondary" className="w-24 capitalize block">
              {key}:
            </Typography.Text>
            <Typography.Text
              type="secondary"
              className="block text-base font-normal"
            >{`${string12hr(
              value?.openTime,
            )}${' '}-${' '}${string12hr(value?.closeTime)}`}</Typography.Text>
          </Space>
        );
      })}
    </div>
  );
}

function string12hr({ time }: { time: string }) {
  const [hourString, minutesString] = time.split(':');
  const hours = parseInt(hourString);
  const minutes = parseInt(minutesString);

  if (hours <= 11) {
    return `${hours === 0 ? '12' : hours}:${minutes > 10 ? minutes : '0' + minutes} AM`;
  }

  return `${hours - 12}:${minutes > 10 ? minutes : '0' + minutes} PM`;
}
