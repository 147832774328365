import PFLogoImage from '../../assets/images/PFlogo.png';

interface LogoProps {
  src?: string;
  alt?: string;
  width?: string | number;
  height?: string | number;
  className?: string;
}

export const PFLogo = ({
  src = PFLogoImage,
  alt = 'Phantom Fireworks',
  width = 132,
  height = 132,
  className = '',
}: LogoProps) => {
  return (
    <div
      className={className}
      style={{
        width,
        height,
      }}
    >
      {src ? <img src={src} alt={alt} className="h-full w-full" /> : null}
    </div>
  );
};
