import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { CSSProperties, useEffect, useMemo, useRef } from 'react';

import './table.css';
import clsx from 'clsx';
import { Spin } from 'antd';

export interface ITableProps extends AgGridReactProps {
  rootStyle?: CSSProperties;
  tableStyle?: CSSProperties;
  rootClassName?: string;
  tableClassName?: string;
  tableRef?: React.MutableRefObject<AgGridReact<any> | null>;
  loading?: boolean;
}

export const Table = (props: ITableProps) => {
  const gridRef = useRef<AgGridReact>(null);
  const {
    rootStyle,
    tableStyle,
    rootClassName,
    tableClassName,
    defaultColDef,
    tableRef,
    loading,
    ...rest
  } = props;
  const _rootStyle = useMemo(() => rootStyle || { width: '100%', height: '100%' }, [rootStyle]);
  const _tableStyle = useMemo(() => tableStyle || { height: '100%', width: '100%' }, [tableStyle]);

  const _defaultColDef = useMemo<ColDef>(() => {
    return {
      filter: true,
      editable: false,
      filterParams: {
        buttons: ['reset'],
      },
      ...defaultColDef,
    };
  }, [defaultColDef]);

  useEffect(() => {
    if (tableRef) {
      tableRef.current = gridRef.current;
    }
  }, [gridRef.current]);

  useEffect(() => {
    if (gridRef.current?.api) {
      if (loading) {
        gridRef.current?.api.showLoadingOverlay();
      }
      if (!loading) {
        gridRef.current?.api.hideOverlay();
      }
    }
  }, [loading, gridRef.current]);

  return (
    <div style={_rootStyle} className={clsx('phantom-table', rootClassName)}>
      <div style={_tableStyle} className={clsx('ag-theme-quartz-dark', tableClassName)}>
        <AgGridReact
          ref={gridRef}
          defaultColDef={_defaultColDef}
          loadingOverlayComponent={Spin}
          {...rest}
        />
      </div>
    </div>
  );
};
