import { actionCreator, asyncActionCreator } from '../../redux/helper';
import { IMongodbUser } from '../../types/mongodb';

enum AuthActionTypes {
  VALIDATE_USER = 'AUTH/VALIDATE_USER',
  LOGOUT_ACCOUNT = 'AUTH/LOGOUT_ACCOUNT',
  LOGIN_ACCOUNT = 'AUTH/LOGIN_ACCOUNT',
  SET_CURRENT_USER = 'AUTH/SET_CURRENT_USER',
  WATCH_CURRENT_USER = 'AUTH/WATCH_CURRENT_USER',
}

export const validateUser = asyncActionCreator(AuthActionTypes.VALIDATE_USER);
export const logoutAccount = asyncActionCreator(AuthActionTypes.LOGOUT_ACCOUNT);
export const loginAccount = asyncActionCreator<undefined, { code: string }>(
  AuthActionTypes.LOGIN_ACCOUNT,
);
export const watchCurrentUser = asyncActionCreator(AuthActionTypes.WATCH_CURRENT_USER);

export const setCurrentUser = actionCreator<IMongodbUser>(AuthActionTypes.SET_CURRENT_USER);
