import { IShopifyProduct } from '../../../types/shopify';
import React from 'react';
import clsx from 'clsx';
import defaultImage from '../../../assets/images/defaultImage.png';
import capitalize from 'lodash/capitalize';
import { Card, Typography } from 'antd';

interface IProductCardProps {
  product: IShopifyProduct;
  className?: string;
}

export const ProductCard = ({
  product,
  className,
  children,
}: React.PropsWithChildren<IProductCardProps>) => {
  const productType = product.productType
    ? product.productType
        .split('/')
        .map((term: string) => capitalize(term))
        .join(' / ')
    : '';
  return (
    <Card
      className={clsx(' border-colorPrimary', className)}
      classNames={{
        body: '!p-2',
      }}
    >
      <div className="flex gap-x-4">
        <div className="w-full max-w-24 flex justify-center items-center">
          <img
            className="w-full h-full object-contain"
            src={product.featuredImage?.url || defaultImage}
            alt={product.title || ''}
          />
        </div>
        <div className="flex flex-col flex-auto gap-y-2">
          <div className="flex flex-col gap-y-1">
            <Typography.Text className="text-lg">{product.itemCode?.value || ''}</Typography.Text>
            <Typography.Text type="secondary">{product.title || ''}</Typography.Text>
            <Typography.Text type="secondary">{`$ ${product.priceRangeV2.minVariantPrice.amount}`}</Typography.Text>
            <Typography.Text type="secondary">{productType}</Typography.Text>
          </div>
          <div>{children}</div>
        </div>
      </div>
    </Card>
  );
};
