export const isUserAllowedAccess = ({
  currentUserRoles,
  rolesNeeded,
}: {
  currentUserRoles: string[];
  rolesNeeded: string[];
}) => {
  let isAllowed = false;
  if (!rolesNeeded.length) isAllowed = false;
  else {
    for (const role of currentUserRoles) {
      if (rolesNeeded.includes(role)) {
        isAllowed = true;
        break;
      }
    }
  }
  return isAllowed;
};
