import { createReducer } from '@reduxjs/toolkit';
import { InitialAsyncState, asyncCaseCreator } from '../../redux/helper';
import { EReduxAsyncState } from '../../types/redux';
import { StoreActions, CommonActions } from '../../redux/actions';
import { IStore } from '../../types';

interface IStoreState {
  stores: InitialAsyncState<IStore[]>;
  updateStore: InitialAsyncState<IStore[]>;
}

const defaultStoreState: IStoreState = {
  stores: { status: EReduxAsyncState.idle },
  updateStore: { status: EReduxAsyncState.idle },
};

export const storeReducer = createReducer(defaultStoreState, builder => {
  asyncCaseCreator(StoreActions.fetchStores, 'stores', builder);
  asyncCaseCreator(StoreActions.updateStores, 'updateStore', builder);
  builder
    .addCase(StoreActions.setStores, (state, action) => {
      state.stores.data = action.payload;
    })
    .addCase(CommonActions.clearReduxStore, (state, action) => {
      return defaultStoreState;
    });
});
