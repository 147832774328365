import { fork, put, takeEvery, call, select } from 'redux-saga/effects';

import { ProductActions } from '../../redux/actions';
import { fetchProducts, updateProducts } from '../../redux/services/productService';
import { ActionType } from 'typesafe-actions';
import { RootState } from '../store';
import { IShopifyProduct } from '../../types/shopify';
import keyBy from 'lodash/keyBy';

export function* fetchProductsSaga(): Generator<unknown, void, any> {
  try {
    yield put(ProductActions.fetchProducts.start());
    const products: Awaited<ReturnType<typeof fetchProducts>> = yield call(fetchProducts);

    yield put(ProductActions.fetchProducts.success(products));
  } catch (error) {
    yield put(ProductActions.fetchProducts.error(error as Error));
  }
}

export function* updateProductsSaga(
  action: ActionType<typeof ProductActions.updateProducts.saga>,
): Generator<unknown, void, any> {
  try {
    const { payload } = action;
    yield put(ProductActions.updateProducts.start());
    const updatedProducts: Awaited<ReturnType<typeof updateProducts>> = yield call(updateProducts, {
      actionType: payload.actionType,
      payload: payload.updatePayload,
    });

    if (updatedProducts.length) {
      const currentProducts: IShopifyProduct[] = yield select(
        (store: RootState) => store.Product?.products?.data,
      );
      if (currentProducts?.length) {
        const keyById = keyBy(updatedProducts, product => product.id);
        const _products = currentProducts.map(value => {
          if (keyById[value.id]) {
            return keyById[value.id];
          }
          return value;
        });
        yield put(ProductActions.setProducts([..._products]));
      }
    }
    yield put(ProductActions.updateProducts.success());
  } catch (error) {
    yield put(ProductActions.updateProducts.error(error as Error));
  }
}

function* fetchProductsSagaListener() {
  yield takeEvery(ProductActions.fetchProducts.saga.toString(), fetchProductsSaga);
}

function* updateProductsSagaListener() {
  yield takeEvery(ProductActions.updateProducts.saga.toString(), updateProductsSaga);
}

export default function* productSaga() {
  yield fork(fetchProductsSagaListener);
  yield fork(updateProductsSagaListener);
}
