/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { EReduxAsyncState } from '../../types/redux';

// Sync action helper
export const actionCreator = createAction;

// Async action helper

export const asyncActionCreator = <
  SuccessPayload = undefined,
  SagaPayload = undefined,
  ErrorPayload = Error,
>(
  baseType: string,
) => {
  return {
    saga: createAction<SagaPayload>(`${baseType}/SAGA`),

    start: createAction(`${baseType}/START`),

    success: createAction<SuccessPayload>(`${baseType}/SUCCESS`),

    error: createAction<ErrorPayload>(`${baseType}/ERROR`),

    reset: createAction(`${baseType}/RESET`, (clearData: boolean = false) => {
      return {
        payload: {
          clearData,
        },
      };
    }),
  };
};

// Async reducer case helper
export interface InitialAsyncState<DataType = undefined, ErrorType = Error> {
  status: EReduxAsyncState;
  error?: ErrorType;
  data?: DataType;
}

export const asyncCaseCreator = <T extends ActionReducerMapBuilder<any>>(
  actions: Record<string, ReturnType<typeof createAction>>,
  propertyName: string,
  builder: T,
) => {
  builder
    .addCase(actions.start, state => {
      if (state[propertyName]) {
        state[propertyName].status = EReduxAsyncState.inProgress;
      }
    })
    .addCase(actions.success, (state, action) => {
      if (action.payload && state[propertyName]) {
        state[propertyName].data = action.payload;
      }
      if (state[propertyName]) {
        state[propertyName].status = EReduxAsyncState.success;
        state[propertyName].error = undefined;
      }
    })
    .addCase(actions.error, (state, action) => {
      if (state[propertyName]) {
        state[propertyName].status = EReduxAsyncState.failed;
        state[propertyName].error = action.payload;
      }
    })
    .addCase(actions.reset, (state, action) => {
      if (action.payload.clearData && state[propertyName]) {
        state[propertyName].data = undefined;
      }
      if (state[propertyName]) {
        state[propertyName].status = EReduxAsyncState.idle;
        state[propertyName].error = undefined;
      }
    });
};
