import { Modal } from 'antd';
import { StoreBopisSettings, IStoreBopisSettingsProps } from '..';
import { IStore } from '../../../types';

export interface IBopisSettingsConfirmationModalProps {
  open: boolean;
  onClose?: () => void;
  onApply?: IStoreBopisSettingsProps['onApply'];
  store: IStore;
  loading?: boolean;
  title: string;
}

export const BopisSettingsConfirmationModal = ({
  open,
  onClose,
  onApply,
  store,
  loading,
  title,
}: IBopisSettingsConfirmationModalProps) => {
  const handelCancelClick = () => {
    onClose?.();
  };

  const handleApplyClick: IStoreBopisSettingsProps['onApply'] = value => {
    onApply?.(value);
  };
  return (
    <Modal
      open={open}
      footer={null}
      onCancel={onClose}
      title={title}
      width={'100%'}
      className=" max-w-4xl"
      centered
    >
      <div className=" pt-3">
        <StoreBopisSettings
          store={store}
          onCancel={handelCancelClick}
          onApply={handleApplyClick}
          loading={loading}
        />
      </div>
    </Modal>
  );
};
