import { createReducer } from '@reduxjs/toolkit';
import { InitialAsyncState, asyncCaseCreator } from '../../redux/helper';
import { EReduxAsyncState } from '../../types/redux';
import { ProductActions, CommonActions } from '../../redux/actions';
import { IShopifyProduct } from '../../types/shopify';

interface IProductState {
  products: InitialAsyncState<IShopifyProduct[]>;
  updateProducts: InitialAsyncState<IShopifyProduct[]>;
}

const defaultProductState: IProductState = {
  products: { status: EReduxAsyncState.idle },
  updateProducts: { status: EReduxAsyncState.idle },
};

export const productReducer = createReducer(defaultProductState, builder => {
  asyncCaseCreator(ProductActions.fetchProducts, 'products', builder);
  asyncCaseCreator(ProductActions.updateProducts, 'updateProducts', builder);
  builder
    .addCase(ProductActions.setProducts, (state, action) => {
      state.products.data = action.payload;
    })
    .addCase(CommonActions.clearReduxStore, (state, action) => {
      return defaultProductState;
    });
});
