import { IMongodbOrder } from '../../types/mongodb';
import { actionCreator, asyncActionCreator } from '../helper/index';
import { IShopifyOrder } from '../../types/shopify/TypeOrder';

enum OrdersActionTypes {
  GET_ORDERS_FOR_STORE = 'ORDERS/GET_ORDERS_FOR_STORE',
  ORDER_COUNT_CHANGES = 'ORDERS/COUNT_CHANGES',
  SET_SHOPIFY_ORDERS = 'ORDERS/SET_SHOPIFY_ORDERS',
  SET_MONGODB_ORDERS = 'ORDERS/SET_MONGODB_ORDERS',
  UPDATE_MONGODB_ORDER = 'ORDERS/UPDATE_MONGODB_ORDER',
}

export const getOrdersForStore = asyncActionCreator<undefined, { id: string }>(
  OrdersActionTypes.GET_ORDERS_FOR_STORE,
);
export const getOrdersCount = asyncActionCreator(OrdersActionTypes.ORDER_COUNT_CHANGES);
export const setShopifyOrders = actionCreator<Map<string, IShopifyOrder>>(
  OrdersActionTypes.SET_SHOPIFY_ORDERS,
);
export const setMongodbOrders = actionCreator<Map<string, IMongodbOrder>>(
  OrdersActionTypes.SET_MONGODB_ORDERS,
);
export const updateMongodbOrder = actionCreator<{ id: string; order: IMongodbOrder }>(
  OrdersActionTypes.UPDATE_MONGODB_ORDER,
);
