import { z } from 'zod';
import {
  EBopisSettingsEarliestPickupDay,
  EBopisSettingsPickupBy,
  EBopisSettingsPickupSlotDuration,
} from './common';

const CustomerNotificationSchema = z.object({
  orderPlaced: z.boolean().optional(),
  orderReadyForPickup: z.boolean().optional(),
  packingDelayed: z.boolean().optional(),
  pickupMissed: z.boolean().optional(),
  pickupMissedFirstNotificationAfter: z.string().optional(),
  pickupMissedSecondNotificationAfter: z.string().optional(),
  orderCancelled: z.boolean().optional(),
  orderPickedUp: z.boolean().optional(),
});

const StoreNotificationSchema = z.object({
  orderPlaced: z.boolean().optional(),
  packingDelayed: z.boolean().optional(),
  packingDelayedFirstNotification: z.string().optional(),
  packingDelayedSecondNotification: z.string().optional(),
  pickupMissed: z.boolean().optional(),
  pickupMissedFirstNotificationAfter: z.string().optional(),
  pickupMissedSecondNotificationAfter: z.string().optional(),
  orderCancelled: z.boolean().optional(),
});

const NotificationSchema = z.object({
  customer: CustomerNotificationSchema,
  store: StoreNotificationSchema,
});

const CompanySettingsSchema = z.object({
  bopisStatus: z.union([z.literal('active'), z.literal('inactive')]),
  bopisBeginDate: z.string(),
  bopisEndDate: z.string(),
  pickupBy: EBopisSettingsPickupBy,
  earliestPickupDay: EBopisSettingsEarliestPickupDay,
  earliestPickupTime: z.string(),
  pickupSlotDuration: EBopisSettingsPickupSlotDuration,
  markInventoryLowBelow: z.number(),
  minOrderValue: z.number(),
  notification: NotificationSchema,
  _id: z.string(),
});

export type IMongodbCompany = z.infer<typeof CompanySettingsSchema>;
