const getRolesNameByWebsiteEnv = <T extends string>(role: T) => {
  return (
    process.env.REACT_APP_WEBSITE_ENV === 'development'
      ? `${role}_dev`
      : process.env.REACT_APP_WEBSITE_ENV === 'stage'
        ? `${role}_qa`
        : `${role}`
  ) as `${T}_dev` | `${T}_qa` | T;
};

export const ONE_LOGIN_ROLES = {
  phantom_bopis_admin: getRolesNameByWebsiteEnv('phantom_bopis_admin'),
  phantom_bopis_fulfillment: getRolesNameByWebsiteEnv('phantom_bopis_fulfillment'),
};

export const STORE_ACCESS_ROLES = {
  phantom_bopis_store_manager: 'phantom_bopis_store_manager',
  phantom_bopis_store_pack: 'phantom_bopis_store_pack',
  phantom_bopis_store_pickup: 'phantom_bopis_store_pickup',
};
