import { z } from 'zod';
import { StringToDateSchema } from '../common';
import { getMongodbStatusHistorySchema } from './common';

export const MongoDbOrderStatusSchema = z.enum([
  'NEEDS_PACKING',
  'PACKING_STARTED',
  'UNFULFILLABLE',
  'READY_FOR_PICKUP',
  'ALREADY_PICKED_UP',
  'CANCELED',
  'CANCELED_RESHELVING_CONFIRMED',
]);

export type IMongoDbOrderStatus = z.infer<typeof MongoDbOrderStatusSchema>;

const MongodbOrderFulfillmentStatusSchema = z.object({
  productId: z.string(),
  filledQty: z.number(),
  substitute: z.array(
    z.object({
      productId: z.string(),
      filledQty: z.number(),
    }),
  ),
});

export type IMongodbOrderFulfillmentStatus = z.infer<typeof MongodbOrderFulfillmentStatusSchema>;

const MongodbOrderStatusHistorySchema = getMongodbStatusHistorySchema(MongoDbOrderStatusSchema);

export type IMongodbOrderStatusHistory = z.infer<typeof MongodbOrderStatusHistorySchema>;

export const MongodbOrderSchema = z.object({
  _id: z.string(), // Shopify Order Id
  shopifyShopId: z.string(),
  shopifyShopName: z.string(),
  fulfillmentLocationId: z.string(), // Shopify Location Id
  fulfillmentLocationCode: z.string(),
  status: MongoDbOrderStatusSchema,
  serverUpdatedOn: StringToDateSchema,
  updatedOn: StringToDateSchema,
  serverCreatedOn: StringToDateSchema,
  createdOn: StringToDateSchema,
  fulfillmentStatus: z.array(MongodbOrderFulfillmentStatusSchema).optional(),
  statusHistory: z.array(MongodbOrderStatusHistorySchema).optional(), // ask vishal whether it will be optional or not?
});

export type IMongodbOrder = z.infer<typeof MongodbOrderSchema>;
