import { useState, useEffect } from 'react';

export enum DeviceType {
  tablet = 'tablet',
  mobile = 'mobile',
  desktop = 'desktop',
}

export const useDeviceType = () => {
  const [deviceType, setDeviceType] = useState(DeviceType.desktop);

  useEffect(() => {
    const checkDeviceType = () => {
      if (window.matchMedia('(max-width: 768px)').matches) {
        setDeviceType(DeviceType.mobile);
      } else if (window.matchMedia('(min-width: 769px) and (max-width: 1024px)').matches) {
        setDeviceType(DeviceType.tablet);
      } else {
        setDeviceType(DeviceType.desktop);
      }
    };

    checkDeviceType();

    window.addEventListener('resize', checkDeviceType);

    return () => {
      window.removeEventListener('resize', checkDeviceType);
    };
  }, []);

  return deviceType;
};
