import { PropsWithChildren } from 'react';
import { DeviceType, useDeviceType } from '../../hooks';
import { BaseDesktopHeader } from './BaseDesktopHeader';
import { BaseMobileHeader } from './BaseMobileHeader';

export const DefaultLayout = (props: PropsWithChildren) => {
  const deviceType = useDeviceType();
  const isMobile = deviceType === DeviceType.mobile;
  return (
    <>
      {isMobile ? <BaseMobileHeader /> : <BaseDesktopHeader />}
      {props.children}
    </>
  );
};
