import { Link, LinkProps } from 'react-router-dom';

interface IMenuItemLabelProps {
  icon?: React.ReactNode;
  title?: string;
  to?: LinkProps['to'];
}

export const MenuItemLabel = ({ icon, title, to }: IMenuItemLabelProps) => {
  const baseComponent = (
    <>
      {!!icon && <span className="leading-[0]"> {icon}</span>}
      {!!title && <span>{title}</span>}
    </>
  );

  const rootClassName = 'flex items-center w-full h-full gap-x-2';

  if (to) {
    return (
      <Link to={to} className={rootClassName}>
        {baseComponent}
      </Link>
    );
  }
  return <div className={rootClassName}>{baseComponent}</div>;
};
