import { callPageFunction } from '../../utils';
import { IContentfulLocation } from '../../types/contentful';
import { getCollection } from '../../realm';
import { StoreActionType, IStoreAction } from '../../../server/stores';
import { IStore } from '../../types';
import { eventChannel, END } from 'redux-saga';
import { EMongoDbCollection, IMongodbStore } from '../../types/mongodb';

export const fetchStores = async () => {
  const { stores } = await callPageFunction('/api/private/stores');
  return stores as IStore[];
};

export const updateStores = async <T extends StoreActionType>({
  actionType,
  payload,
}: {
  actionType: T;
  payload: Parameters<IStoreAction[T]>[1];
}) => {
  await callPageFunction('/api/private/stores/action', {
    body: {
      actionType,
      payload,
    },
  });
};

export const mongodbStoresWatchEmitter = async () => {
  return eventChannel(emitter => {
    const storesCollection = getCollection<IMongodbStore>(EMongoDbCollection.stores);
    const changeStream = storesCollection.watch();
    const watchData = async () => {
      try {
        for await (const change of changeStream) {
          emitter(change);
        }
      } catch (e) {
        throw e;
      }
    };
    watchData();
    return () => {
      changeStream.return('');
    };
  });
};
