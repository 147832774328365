import {
  fork,
  put,
  takeEvery,
  call,
  select,
  takeLeading,
  takeLatest,
  take,
} from 'redux-saga/effects';
import { EventChannel } from 'redux-saga';
import { StoreActions, CommonActions } from '../../redux/actions';
import {
  fetchStores,
  updateStores,
  mongodbStoresWatchEmitter,
} from '../../redux/services/storeService';
import { ActionType } from 'typesafe-actions';
import { RootState } from '../store';
import { IContentfulLocation } from '../../types/contentful';
import { IStore } from '../../types';
import { mergeMongodbAndContentfulStores } from '../../utils';

export function* fetchStoresSaga(): Generator<unknown, void, any> {
  try {
    yield put(StoreActions.fetchStores.start());
    const locations: Awaited<ReturnType<typeof fetchStores>> = yield call(fetchStores);
    yield put(StoreActions.watchStores());
    yield put(StoreActions.fetchStores.success(locations));
  } catch (error) {
    yield put(StoreActions.fetchStores.error(error as Error));
  }
}

export function* watchStoresSaga(): Generator<unknown, void, any> {
  const chan: EventChannel<any> = yield call(mongodbStoresWatchEmitter);
  try {
    while (true) {
      const change = yield take(chan);
      const storesData: IStore[] = yield select((state: RootState) => state.Store.stores.data);

      if (change.operationType === 'insert' || change.operationType === 'update') {
        const fullDocument = change.fullDocument;
        const updatedStores = mergeMongodbAndContentfulStores({
          mongodbStores: [fullDocument],
          contentfulStores: storesData,
        });
        yield put(StoreActions.setStores(updatedStores));
      }
    }
  } catch (error) {
    chan.close();
  } finally {
    chan.close();
  }
}

export function* updateStoresSaga(
  action: ActionType<typeof StoreActions.updateStores.saga>,
): Generator<unknown, void, any> {
  try {
    const { payload } = action;
    yield put(StoreActions.updateStores.start());
    yield call(updateStores, {
      actionType: payload.actionType,
      payload: payload.updatePayload,
    });
    yield put(StoreActions.updateStores.success());
  } catch (error) {
    yield put(StoreActions.updateStores.error(error as Error));
  }
}

function* fetchStoresSagaListener() {
  yield takeLatest(StoreActions.fetchStores.saga.toString(), fetchStoresSaga);
}

function* updateStoresSagaListener() {
  yield takeEvery(StoreActions.updateStores.saga.toString(), updateStoresSaga);
}

function* watchStoresSagaListener() {
  yield takeLatest(StoreActions.watchStores.toString(), watchStoresSaga);
}

export default function* storeSaga() {
  yield fork(fetchStoresSagaListener);
  yield fork(updateStoresSagaListener);
  yield fork(watchStoresSagaListener);
}
