import { createReducer } from '@reduxjs/toolkit';
import { asyncCaseCreator, InitialAsyncState } from '../helper';
import { CompanyActions } from '../actions';
import { IMongodbCompany } from '../../types/mongodb';
import { EReduxAsyncState } from '../../types/redux';

interface ICompanyState {
  companySettings?: IMongodbCompany;
  getCompanySettings: InitialAsyncState;
  addCompanySettings: InitialAsyncState;
}

const defaultCompanyState: ICompanyState = {
  companySettings: undefined,
  addCompanySettings: { status: EReduxAsyncState.idle },
  getCompanySettings: { status: EReduxAsyncState.idle },
};

export const companyReducer = createReducer(defaultCompanyState, builder => {
  asyncCaseCreator(CompanyActions.getCompanySettings, 'getCompanySettings', builder);
  asyncCaseCreator(CompanyActions.addCompanySettings, 'addCompanySettings', builder);

  builder.addCase(CompanyActions.setCompanySettings, (state, action) => {
    state.companySettings = action.payload;
  });
});
