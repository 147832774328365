import { Space, Typography, Button, theme, Divider, Modal } from 'antd';
import 'leaflet/dist/leaflet.css';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { IStore, RouteNames } from '../../../types';
import { EStoreBopisStatus, getStoreBopisStatus } from '../../../utils';
import {
  BopisSettingsConfirmationModal,
  IBopisSettingsConfirmationModalProps,
} from './BopisSettingsConfirmationModal';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { StoreActions } from '../../../redux/actions';
import { EReduxAsyncState } from '../../../types/redux';
import { StoreCardBase } from './StoreCardBase';
import { BopisSettingsEarliestPickupTimeFormat } from '../../../types/mongodb';

const { useToken } = theme;

interface IStoreCardProps {
  isStoreDetailsPage?: boolean;
  className?: string;
  store: IStore;
  position?: number;
}

export const StoreCard = ({
  isStoreDetailsPage = false,
  className = '',
  store,
  position,
}: IStoreCardProps) => {
  const dispatch = useAppDispatch();
  const updateStore = useAppSelector(store => store.Store.updateStore);
  const { token } = useToken();
  const bopisStatus = getStoreBopisStatus(store);
  const [openStartBopisConfirmationModal, setOpenStartBopisConfirmationModal] = useState(false);
  const [openStopBopisConfirmationModal, setOpenStopBopisConfirmationModal] = useState(false);
  const [openDeactivateConfirmationModal, setOpenDeactivateConfirmationModal] = useState(false);
  const [openActivateConfirmationModal, setOpenActivateConfirmationModal] = useState(false);

  const handleStartBopisApply: IBopisSettingsConfirmationModalProps['onApply'] = value => {
    if (updateStore.status === EReduxAsyncState.inProgress) {
      return;
    }
    dispatch(
      StoreActions.updateStores.saga({
        actionType: 'startBopis',
        updatePayload: {
          contentfulLocationId: store.sys.id,
          bopisSettings: {
            ...value,
            earliestPickupTime: value.earliestPickupTime?.format(
              BopisSettingsEarliestPickupTimeFormat,
            ),
            bopisBeginDate: value.bopisBeginDate?.toISOString(),
            bopisEndDate: value.bopisEndDate?.toISOString(),
          },
        },
      }),
    );
  };

  const handleStartBopisClick = () => {
    if (updateStore.status === EReduxAsyncState.inProgress) {
      return;
    }
    setOpenStartBopisConfirmationModal(prev => !prev);
  };

  const handleStopBopisClick = () => {
    if (updateStore.status === EReduxAsyncState.inProgress) {
      return;
    }
    setOpenStopBopisConfirmationModal(prev => !prev);
  };
  const handleDeactivateClick = () => {
    if (updateStore.status === EReduxAsyncState.inProgress) {
      return;
    }
    setOpenDeactivateConfirmationModal(prev => !prev);
  };

  const handleStopBopisOkClick = () => {
    if (updateStore.status === EReduxAsyncState.inProgress) {
      return;
    }
    if (store._id)
      dispatch(
        StoreActions.updateStores.saga({
          actionType: 'stopBopis',
          updatePayload: {
            mongodbStoreId: store._id,
          },
        }),
      );
  };

  const handleDeactivateOkClick = () => {
    if (updateStore.status === EReduxAsyncState.inProgress) {
      return;
    }
    if (store._id)
      dispatch(
        StoreActions.updateStores.saga({
          actionType: 'deactivateBopis',
          updatePayload: {
            mongodbStoreId: store._id,
          },
        }),
      );
  };

  const handleActivateClick = () => {
    if (updateStore.status === EReduxAsyncState.inProgress) {
      return;
    }
    setOpenActivateConfirmationModal(prev => !prev);
  };

  const handleActivateClickApply: IBopisSettingsConfirmationModalProps['onApply'] = value => {
    if (updateStore.status === EReduxAsyncState.inProgress) {
      return;
    }
    if (store._id)
      dispatch(
        StoreActions.updateStores.saga({
          actionType: 'activateBopis',
          updatePayload: {
            mongodbStoreId: store._id,
            bopisSettings: {
              ...value,
              earliestPickupTime: value.earliestPickupTime?.format(
                BopisSettingsEarliestPickupTimeFormat,
              ),
              bopisBeginDate: value.bopisBeginDate?.toISOString(),
              bopisEndDate: value.bopisEndDate?.toISOString(),
            },
          },
        }),
      );
  };

  useEffect(() => {
    if (updateStore.status === EReduxAsyncState.success) {
      setOpenDeactivateConfirmationModal(false);
      setOpenStartBopisConfirmationModal(false);
      setOpenStopBopisConfirmationModal(false);
      setOpenActivateConfirmationModal(false);
    }
  }, [updateStore.status]);

  // useEffect(() => {
  //   console.log('store card', store);
  // }, [store]);
  return (
    <StoreCardBase store={store} position={position} className={className}>
      {bopisStatus === EStoreBopisStatus.NoBopis && (
        <>
          <div className="flex justify-end items-center">
            <Button type="primary" shape="round" onClick={handleStartBopisClick}>
              Start BOPIS
            </Button>
          </div>
          <BopisSettingsConfirmationModal
            title={`Start BOPIS for ${store.title}`}
            open={openStartBopisConfirmationModal}
            onClose={handleStartBopisClick}
            onApply={handleStartBopisApply}
            store={store}
            loading={updateStore.status === EReduxAsyncState.inProgress}
          />
        </>
      )}
      {(bopisStatus === EStoreBopisStatus.Active ||
        bopisStatus === EStoreBopisStatus.Scheduled ||
        bopisStatus === EStoreBopisStatus.Inactive) && (
        <>
          {!isStoreDetailsPage && <Divider />}

          <div className="">
            {!isStoreDetailsPage && (
              <div className=" flex flex-wrap justify-between items-center mb-3">
                <Space>
                  <Typography.Text>Open Orders:</Typography.Text>
                  <Typography.Text type="secondary">7</Typography.Text>
                </Space>
                <Space>
                  <Typography.Text>Begin Date:</Typography.Text>
                  <Typography.Text type="secondary">
                    {store.bopisSettings?.bopisBeginDate
                      ? dayjs(store.bopisSettings?.bopisBeginDate).format('MM/DD/YYYY')
                      : '-'}
                  </Typography.Text>
                </Space>
                <Space>
                  <Typography.Text>End Date:</Typography.Text>
                  <Typography.Text type="secondary">
                    {store.bopisSettings?.bopisEndDate
                      ? dayjs(store.bopisSettings?.bopisEndDate).format('MM/DD/YYYY')
                      : '-'}
                  </Typography.Text>
                </Space>
              </div>
            )}

            <div className="flex justify-between items-center">
              <Space>
                <Button danger type="primary" shape="round" onClick={handleStopBopisClick}>
                  Stop BOPIS
                </Button>
                <Modal
                  centered
                  open={openStopBopisConfirmationModal}
                  title={`Stop BOPIS for ${store.title}`}
                  onCancel={handleStopBopisClick}
                  onOk={handleStopBopisOkClick}
                  confirmLoading={updateStore.status === EReduxAsyncState.inProgress}
                >
                  {`Are you sure want to stop BOPIS for the ${store.title}?`}
                </Modal>
                {bopisStatus !== EStoreBopisStatus.Inactive ? (
                  <>
                    <Button
                      type="primary"
                      className=" bg-colorWarningText hover:!bg-colorWarningTextHover"
                      shape="round"
                      onClick={handleDeactivateClick}
                    >
                      Deactivate
                    </Button>
                    <Modal
                      centered
                      open={openDeactivateConfirmationModal}
                      title={`Deactivated ${store.title} for BOPIS`}
                      onCancel={handleDeactivateClick}
                      onOk={handleDeactivateOkClick}
                      confirmLoading={updateStore.status === EReduxAsyncState.inProgress}
                    >
                      {`Are you sure want to deactivate the ${store.title} for BOPIS?`}
                    </Modal>
                  </>
                ) : (
                  <>
                    <Button type="primary" shape="round" onClick={handleActivateClick}>
                      Activate
                    </Button>
                    <BopisSettingsConfirmationModal
                      title={`Activate BOPIS for ${store.title}`}
                      open={openActivateConfirmationModal}
                      onClose={handleActivateClick}
                      onApply={handleActivateClickApply}
                      store={store}
                      loading={updateStore.status === EReduxAsyncState.inProgress}
                    />
                  </>
                )}
              </Space>
              {!isStoreDetailsPage && (
                <Space>
                  <Link
                    to={RouteNames.AdminSettingsStoresDetails.replace(':storeId', store.sys.id)}
                  >
                    <Button type="primary" shape="round">
                      View Details
                    </Button>
                  </Link>
                </Space>
              )}
            </div>
          </div>
        </>
      )}
    </StoreCardBase>
  );
};
