import { eventChannel, END } from 'redux-saga';
import { getCollection } from '../../realm';
import { callPageFunction } from '../../utils/callPageFunction';
import { IShopifyOrder } from '../../types/shopify/TypeOrder';
import { IMongodbOrder } from '../../types/mongodb';

export const getOrdersForStore = async (
  id: string,
): Promise<{ shopifyOrders: IShopifyOrder[]; mongodbOrders: IMongodbOrder[] } | never> => {
  try {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const ordersCollection = getCollection<IMongodbOrder>('orders');

    const mongodbOrders = await ordersCollection.find({
      $and: [
        { fulfillmentLocationId: id },
        {
          $or: [
            {
              status: {
                $in: [
                  'NEEDS_PACKING',
                  'PACKING_STARTED',
                  'CANCELLED',
                  'READY_FOR_PICKUP',
                  'UNFULFILLABLE',
                ],
              },
            },
            {
              $and: [
                { status: 'ALREADY_PICKED_UP' },
                {
                  'serverUpdatedOn.$date': {
                    $gt: new Date(today).toJSON(),
                  },
                },
              ],
            },
          ],
        },
      ],
    });

    const shopifyOrders = await callPageFunction(
      '/api/private/shopify/orders?locationId=91369701669',
    );
    return { shopifyOrders: shopifyOrders.orders.nodes, mongodbOrders };
  } catch (error) {
    throw error;
  }
};

export const mongoDBWatchEmitter = async () => {
  return eventChannel(emitter => {
    const ordersCollection = getCollection('orders');
    const watchData = async () => {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set time to 00:00:00.000 UTC

      const tomorrow = new Date(today);
      tomorrow.setUTCDate(today.getUTCDate() + 1); // Set time to 00:00:00.000 UTC of the next day
      const filter = {
        filter: {
          $and: [
            { 'fullDocument.fulfillmentLocationId': '91369701669' },
            {
              $or: [
                { 'fullDocument.status': 'NEEDS_PACKING' },
                { 'fullDocument.status': 'PACKING_STARTED' },
                { 'fullDocument.status': 'CANCELLED' },
                { 'fullDocument.status': 'UNFULFILLABLE' },
                { 'fullDocument.status': 'READY_FOR_PICKUP' },
                {
                  'fullDocument.status': 'ALREADY_PICKED_UP',
                  'fullDocument.serverUpdatedOn.$date': {
                    $gte: new Date(today).toJSON(),
                  },
                },
              ],
            },
          ],
        },
      };
      try {
        for await (const change of ordersCollection.watch(filter)) {
          emitter(change);
        }
      } catch (e) {
        throw e;
      }
    };
    watchData();
    return async () => {
      const changeStream = ordersCollection.watch();
      changeStream.return('closed');
    };
  });
};
