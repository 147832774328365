import { Alert, Button, Typography } from 'antd';
import { DefaultLayout } from '../Layout';
import { Link } from 'react-router-dom';
import { matchPath, useLocation } from 'react-router-dom';
import { RouteNames } from '../../types';
import { ONE_LOGIN_ROLES } from '../../constants';

interface IInvalidRolesProps {
  rolesNeeded: string[];
  userRoles: string[];
}

export const InvalidRoles = ({ rolesNeeded = [], userRoles = [] }: IInvalidRolesProps) => {
  const location = useLocation();
  return (
    <DefaultLayout>
      <div className="max-w-2xl p-4 mx-auto">
        <Alert
          message="Access permission denied"
          description={
            <>
              <Typography.Paragraph>
                Sorry, you are not authorized to access this page. Please contact your administrator
              </Typography.Paragraph>
              <Typography.Paragraph>
                <Typography.Text strong>Required Possible Roles:</Typography.Text>
                <br />
                {rolesNeeded.map(role => (
                  <>
                    <Typography.Text>{role}</Typography.Text>
                    <br />
                  </>
                ))}
              </Typography.Paragraph>
              <Typography.Paragraph>
                <Typography.Text strong>Current User Roles:</Typography.Text>
                <br />
                {userRoles.map(role => (
                  <>
                    <Typography.Text>{role}</Typography.Text>
                    <br />
                  </>
                ))}
              </Typography.Paragraph>
            </>
          }
          type="error"
          showIcon
        />
        {!matchPath(RouteNames.Home, location.pathname) && (
          <div className=" mt-4 text-center">
            <Link to="/">
              <Button size="large" type="primary">
                Go back to home page
              </Button>
            </Link>
          </div>
        )}
      </div>
    </DefaultLayout>
  );
};
