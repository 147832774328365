import { actionCreator, asyncActionCreator } from '../helper/index';

enum CompanySettingsActionTypes {
  GET_COMPANY_SETTINGS = 'COMPANY/GET_COMPANY_SETTINGS',
  ADD_COMPANY_SETTINGS = 'COMPANY/ADD_COMPANY_SETTINGS',
  SET_COMPANY_SETTINGS = 'COMPANY/SET_COMPANY_SETTINGS',
}

export const getCompanySettings = asyncActionCreator<undefined, undefined>(
  CompanySettingsActionTypes.GET_COMPANY_SETTINGS,
);

export const addCompanySettings = asyncActionCreator<undefined, any>(
  CompanySettingsActionTypes.ADD_COMPANY_SETTINGS,
);

export const setCompanySettings = actionCreator<any>(
  CompanySettingsActionTypes.SET_COMPANY_SETTINGS,
);
