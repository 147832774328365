import { eventChannel } from 'redux-saga';
import { getCollection } from '../../realm';
import { EMongoDbCollection, IMongodbUser } from '../../types/mongodb';

export const watchUserChanges = async () => {
  return eventChannel(emitter => {
    const storesCollection = getCollection<IMongodbUser>(EMongoDbCollection.users);
    const changeStream = storesCollection.watch();
    const watchData = async () => {
      try {
        for await (const change of changeStream) {
          emitter(change);
        }
      } catch (e) {
        console.log('e', e);
        throw e;
      }
    };
    watchData();
    return () => {
      changeStream.return('');
    };
  });
};
