import { z } from 'zod';

export const StringToDateSchema = z.string().datetime().pipe(z.coerce.date());

export interface IOneLoginTokenData {
  access_token: string;
  expires_in: number;
  id_token: string;
  refresh_token: string;
  scope: string;
  token_type: string;
}

export interface IRealmAccessTokenData {
  sub: string;
  exp: number;
  iat: number;
  iss: string;
  domain_id: string;
  device_id: string;
}

export enum EAppErrorCode {
  PAGE_FUNCTION_UNAUTHORIZED_ACCESS = 'PAGE_FUNCTION_UNAUTHORIZED_ACCESS',
  PAGE_FUNCTION_SERVER_ERROR = 'PAGE_FUNCTION_SERVER_ERROR',
  SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  VERIFY_ONE_LOGIN_ERROR = 'VERIFY_ONE_LOGIN_ERROR',
  REALM_ACCESS_TOKEN_EXPIRED = 'REALM_ACCESS_TOKEN_EXPIRED',
  REALM_ACCESS_TOKEN_IS_INVALID = 'REALM_ACCESS_TOKEN_IS_INVALID',
}

export enum RouteNames {
  Login = '/login',
  Home = '/',
  Fulfillment = '/fulfillment/:storeId',
  FulfillmentReadyForPickup = '/fulfillment/:storeId/ready-for-pickup',
  FulfillmentReadyForPickupDetails = '/fulfillment/:storeId/ready-for-pickup/:orderId',
  FulfillmentNeedPacking = '/fulfillment/:storeId/need-packing',
  FulfillmentNeedPackingOrderId = '/fulfillment/:storeId/need-packing/:orderId',
  FulfillmentAlreadyPickedUp = '/fulfillment/:storeId/already-picked-up',
  FulfillmentCancelledOrders = '/fulfillment/:storeId/cancelled-orders',
  Admin = '/admin',
  AdminSettings = '/admin/settings',
  AdminSettingsCompany = '/admin/settings/company',
  AdminSettingsProducts = '/admin/settings/products',
  AdminSettingsStores = '/admin/settings/stores',
  AdminSettingsStoresDetails = '/admin/settings/stores/:storeId',
  AdminSettingsUsers = '/admin/settings/users',
  AdminAnalyze = '/admin/analyze',
  AdminAnalyzeOrders = '/admin/analyze/orders',
  AdminAnalyzeProducts = '/admin/analyze/products',
  AdminAnalyzeStores = '/admin/analyze/stores',
  AdminAnalyzeUsers = '/admin/analyze/users',
}

export interface IRouteHandle {
  requiredOneLoginRoles?: string[];
  requiredStoreAccessRoles?: string[];
}
