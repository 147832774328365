import {
  call,
  cancelled,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { UserActions } from '../actions';

import { ActionType } from 'typesafe-actions';
import { callPageFunction } from '../../utils';
import { allUsers } from '../selectors/userSelector';

import { watchUserChanges } from '../services/userService';
import { EventChannel } from 'redux-saga';
import { IMongodbUser } from '../../types/mongodb';

interface IGetAdminAndFulfillmentGenerator {
  users: IMongodbUser[];
}

export function* getUsersSaga(
  action: ActionType<typeof UserActions.getUsers.saga>,
): Generator<unknown, void, IGetAdminAndFulfillmentGenerator> {
  try {
    yield put(UserActions.getUsers.start());
    const { users } = yield call(callPageFunction, '/api/private/mongodb/getUsers');

    yield put(UserActions.setUsers(users));
    yield put(UserActions.watchUser());
  } catch (error: unknown) {
    console.log('error', error);
    UserActions.getUsers.error(error as Error);
  }
}

export function* updateUserSaga(
  action: ActionType<typeof UserActions.updateUser.saga>,
): Generator<unknown, void, unknown> {
  const controller = new AbortController();
  try {
    yield put(UserActions.updateUser.start());
    const user = action.payload;
    yield call(callPageFunction, '/api/private/mongodb/updateUser', {
      body: { user } as any,
      signal: controller.signal,
    });
    UserActions.updateUser.success();
  } catch (error: unknown) {
    UserActions.updateUser.error(error as Error);
  } finally {
    if (yield cancelled()) {
      controller.abort();
    }
  }
}

export function* watchUserChangesSaga(): Generator<unknown, void, any> {
  const channel: EventChannel<any> = yield call(watchUserChanges);
  try {
    while (true) {
      const change = yield take(channel);
      const users: IMongodbUser[] = yield select(allUsers);

      const { fullDocument }: { fullDocument: IMongodbUser } = change;
      const index = users.findIndex(user => user._id === fullDocument._id);

      if (index !== -1) {
        const updatedUsers = [...users];
        updatedUsers[index] = fullDocument;
        yield put(UserActions.setUsers(updatedUsers));
      } else {
        yield put(UserActions.setUsers([...users, fullDocument]));
      }
    }
  } catch (error) {
    console.log('error', error);
  } finally {
    channel.close();
  }
}

function* getAdminAndFulfillmentUsersListener() {
  yield takeLatest(UserActions.getUsers.saga.toString(), getUsersSaga);
}

function* updateUserListener() {
  yield takeEvery(UserActions.updateUser.saga.toString(), updateUserSaga);
}

function* watchUserSagaListener() {
  yield takeLatest(UserActions.watchUser.toString(), watchUserChangesSaga);
}

export default function* userSaga() {
  yield fork(getAdminAndFulfillmentUsersListener);
  yield fork(updateUserListener);
  yield fork(watchUserSagaListener);
}
