import { CustomCellRendererProps } from 'ag-grid-react';
import { useFetchProducts } from '../../../hooks/Admin';
import { Button, Checkbox, Input, Modal, Space, Typography } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { CustomTextCell } from '../../Table/CustomComponents';
import { ClearIcon, DeleteIcon, EyeIcon, SearchIcon, TaskIcon } from '../../Icons';
import { IProduct } from '../../../types';
import React from 'react';
import clsx from 'clsx';
import { Virtuoso } from 'react-virtuoso';
import Fuse from 'fuse.js';
import { useDispatch } from 'react-redux';
import { ProductActions } from '../../../redux/actions';
import { useAppSelector } from '../../../redux/hooks';
import { EReduxAsyncState } from '../../../types/redux';
import { ProductCard } from '../ProductCard';
import { getProductsWithSamePriceAndCategory } from '../../../utils';

export const CustomCellSubstitutes = (props: CustomCellRendererProps<IProduct>) => {
  const dispatch = useDispatch();
  const updateProducts = useAppSelector(state => state.Product.updateProducts);
  const products = useFetchProducts();
  const currentProduct = props.data;
  const [openModal, setOpenModal] = useState(false);

  const [defaultSubstituteCheckbox, setDefaultSubstituteCheckbox] = useState(false);
  const [addSubstitute, setAddSubstitute] = useState<IProduct[]>([]);
  const [removeSubstitute, setRemoveSubstitute] = useState<IProduct[]>([]);
  const [substituteProducts, setSubstituteProducts] = useState<IProduct[]>([]);
  const [nonSubstituteProducts, setNonSubstituteProducts] = useState<IProduct[]>([]);
  const [isClearList, setIsClearList] = useState(false);
  const [searchResult, setSearchResult] = useState<IProduct[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const updateProductsInProgress = updateProducts.status === EReduxAsyncState.inProgress;

  const productsWithSamePriceAndCategory = useMemo(() => {
    if (products.data && currentProduct) {
      return getProductsWithSamePriceAndCategory(products.data, currentProduct);
    }
    return [];
  }, [products.data, currentProduct]);

  const currentDefaultSubstituteStatus = currentProduct?.defaultSubstitutes?.value === 'true';

  const currentSubstituteIds = useMemo(
    () =>
      currentProduct?.substitutes?.value
        ? (JSON.parse(currentProduct?.substitutes?.value) as string[])
        : [],
    [currentProduct],
  );

  const currentSubstituteProducts = useMemo(() => {
    if (products.data?.length) {
      return products.data.filter(product => currentSubstituteIds.includes(product.id));
    }

    return [];
  }, [products.data, currentSubstituteIds]);

  const currentNonSubstituteProducts = useMemo(() => {
    if (products.data?.length && currentProduct) {
      return products.data.filter(
        product => ![...currentSubstituteIds, currentProduct.id].includes(product.id),
      );
    }

    return [];
  }, [products.data, currentSubstituteIds, currentProduct]);

  const handleRemove = (product: IProduct) => {
    if (defaultSubstituteCheckbox) {
      return;
    }
    setIsClearList(false);
    if (currentSubstituteIds.includes(product.id)) {
      setRemoveSubstitute(prev => [product, ...prev]);
    }

    setNonSubstituteProducts(prev => [product, ...prev]);
    setAddSubstitute(prev => prev.filter(value => value.id !== product.id));
    setSubstituteProducts(prev => prev.filter(value => value.id !== product.id));
  };
  const handleAdd = (product: IProduct) => {
    if (defaultSubstituteCheckbox) {
      return;
    }
    setIsClearList(false);
    setSubstituteProducts(prev => [product, ...prev]);
    if (!currentSubstituteIds.includes(product.id)) {
      setAddSubstitute(prev => [product, ...prev]);
    }
    setRemoveSubstitute(prev => prev.filter(value => value.id !== product.id));
    setNonSubstituteProducts(prev => prev.filter(value => value.id !== product.id));
  };
  const handleSearchInputChange = (e: any) => {
    setSearchValue(e.target.value);
  };

  const handleDefaultSubstituteCheckboxChange = () => {
    setIsClearList(false);
    if (!defaultSubstituteCheckbox) {
      setDefaultSubstituteCheckbox(true);
      setSubstituteProducts(productsWithSamePriceAndCategory);
      setAddSubstitute([]);
      setRemoveSubstitute([]);
      setNonSubstituteProducts([...currentSubstituteProducts, ...currentNonSubstituteProducts]);
      return;
    }

    setDefaultSubstituteCheckbox(false);
    setSubstituteProducts(currentSubstituteProducts);
    setAddSubstitute([]);
    setRemoveSubstitute([]);
    setNonSubstituteProducts(currentNonSubstituteProducts);
  };

  const handleClearListClick = () => {
    setIsClearList(true);
    setDefaultSubstituteCheckbox(false);
    setAddSubstitute([]);
    setSubstituteProducts([]);
    setRemoveSubstitute([]);
  };

  const handleCancel = (closeModal?: boolean) => {
    setAddSubstitute([]);
    setRemoveSubstitute([]);
    setDefaultSubstituteCheckbox(currentDefaultSubstituteStatus);
    setSubstituteProducts(
      currentDefaultSubstituteStatus ? productsWithSamePriceAndCategory : currentSubstituteProducts,
    );
    setNonSubstituteProducts(currentNonSubstituteProducts);
    setIsClearList(false);
    if (closeModal) {
      setOpenModal(false);
    }
  };

  const handleConfirmSubstitutesClick = () => {
    if (!props.data?.id) {
      return;
    }
    if (isClearList) {
      dispatch(
        ProductActions.updateProducts.saga({
          actionType: 'clearSubstitutes',
          updatePayload: {
            shopifyId: props.data?.id,
          },
        }),
      );
      return;
    }

    if (
      defaultSubstituteCheckbox ||
      (currentDefaultSubstituteStatus && !defaultSubstituteCheckbox && !addSubstitute.length)
    ) {
      dispatch(
        ProductActions.updateProducts.saga({
          actionType: 'setDefaultSubstitutes',
          updatePayload: {
            shopifyId: props.data?.id,
            defaultSubstitutes: defaultSubstituteCheckbox,
          },
        }),
      );
      return;
    }

    if (!defaultSubstituteCheckbox && (addSubstitute.length || removeSubstitute.length)) {
      dispatch(
        ProductActions.updateProducts.saga({
          actionType: 'setSubstitutes',
          updatePayload: {
            shopifyId: props.data?.id,
            addSubstitutedProductId: addSubstitute.map(product => product.id),
            removeSubstitutedProductId: removeSubstitute.map(product => product.id),
          },
        }),
      );
    }
  };

  useEffect(() => {
    if (currentDefaultSubstituteStatus) {
      setDefaultSubstituteCheckbox(currentDefaultSubstituteStatus);
      setSubstituteProducts(productsWithSamePriceAndCategory);
    } else {
      setDefaultSubstituteCheckbox(currentDefaultSubstituteStatus);
      setSubstituteProducts(currentSubstituteProducts);
    }
  }, [currentSubstituteProducts, currentDefaultSubstituteStatus, productsWithSamePriceAndCategory]);

  useEffect(() => {
    setNonSubstituteProducts(currentNonSubstituteProducts);
  }, [currentNonSubstituteProducts, currentDefaultSubstituteStatus]);

  useEffect(() => {
    const fuse = new Fuse(nonSubstituteProducts, {
      keys: ['title', 'itemCode.value'],
    });
    const timerId = setTimeout(() => {
      const results = fuse.search(searchValue);
      setSearchResult(results.map(result => result.item));
    }, 500);
    return () => {
      clearTimeout(timerId);
    };
  }, [searchValue, nonSubstituteProducts]);

  useEffect(() => {
    if (updateProducts.status === EReduxAsyncState.success) {
      handleCancel(false);
    }
  }, [updateProducts.status]);

  return (
    <>
      <CustomTextCell
        {...props}
        showValue={true}
        endComponent={<Button onClick={() => setOpenModal(true)} icon={<EyeIcon />} type="text" />}
      />
      <Modal
        width={'100%'}
        centered
        title={`Substitutes for ${currentProduct?.itemCode?.value || ''}: ${currentProduct?.title}`}
        open={openModal}
        onCancel={() => handleCancel(true)}
        footer={null}
        className=" max-w-5xl h-[80%] overflow-hidden relative [&_>_div:has(.ant-modal-content)]:h-full"
        classNames={{
          wrapper: '',
          content: 'absolute w-full h-full  flex flex-col  overflow-hidden',
          body: ' h-full overflow-hidden w-full',
          header: 'border-0 !border-b !border-solid !border-colorBorder !mb-0 !pb-2 ',
        }}
      >
        <div className="w-full h-full flex flex-col overflow-hidden gap-y-4">
          <div className="w-full h-full flex  flex-row overflow-hidden">
            <div className=" basis-1/2 flex flex-col h-full border-solid border-0 border-r-[0.5px] border-colorBorder overflow-hidden gap-y-2 pt-3">
              <div className="pr-3 flex flex-row  gap-x-2 justify-center items-center">
                <SearchIcon />
                <Input
                  className="flex-auto"
                  value={searchValue}
                  onChange={handleSearchInputChange}
                />
                <Button
                  icon={<ClearIcon />}
                  type="text"
                  shape="circle"
                  onClick={() => setSearchValue('')}
                />
              </div>
              <div className="flex-col flex h-full w-full overflow-hidden gap-y-2">
                <Virtuoso
                  style={{ height: '100%' }}
                  className=" [&_>_div]:pr-3"
                  data={searchResult.length ? searchResult : nonSubstituteProducts}
                  itemContent={(index, product) => (
                    <ProductCard product={product} className="mb-3">
                      <div className=" flex justify-end items-center">
                        <Button
                          disabled={updateProductsInProgress || defaultSubstituteCheckbox}
                          type="primary"
                          shape="round"
                          onClick={() => handleAdd(product)}
                        >
                          Add
                        </Button>
                      </div>
                    </ProductCard>
                  )}
                />
              </div>
            </div>
            <div className=" basis-1/2 flex flex-col h-full border-solid border-0 border-l-[0.5px] border-colorBorder overflow-auto gap-y-2 pt-3">
              <div className="pl-3 flex flex-col  gap-y-2">
                <Typography.Text>{`${substituteProducts.length} Substitutable ${substituteProducts.length > 1 ? 'Items' : 'Item'}`}</Typography.Text>
                <Checkbox
                  checked={defaultSubstituteCheckbox}
                  onChange={handleDefaultSubstituteCheckboxChange}
                >
                  <Typography.Text type="secondary">
                    All items of the same category and with the same price
                  </Typography.Text>
                </Checkbox>
              </div>
              <div className=" flex-col flex h-full w-full overflow-hidden gap-y-2">
                {defaultSubstituteCheckbox && !substituteProducts.length && (
                  <div className=" px-3 w-full h-full flex justify-center items-center">
                    <Typography.Text type="warning" className=" w-4/5">
                      There is no product in the same category and with the same price
                    </Typography.Text>
                  </div>
                )}
                <Virtuoso
                  style={{
                    height: '100%',
                    display:
                      defaultSubstituteCheckbox && !substituteProducts.length ? 'none' : undefined,
                  }}
                  className=" [&_>_div]:pl-3"
                  data={substituteProducts}
                  itemContent={(index, product) => (
                    <ProductCard product={product} className="mb-3">
                      {!defaultSubstituteCheckbox ? (
                        <div className=" flex justify-end items-center">
                          <Button
                            disabled={updateProductsInProgress}
                            type="primary"
                            shape="round"
                            onClick={() => handleRemove(product)}
                          >
                            Remove
                          </Button>
                        </div>
                      ) : (
                        <></>
                      )}
                    </ProductCard>
                  )}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end items-center ">
            <Space>
              <Button
                className="flex justify-center items-center"
                // loading={activeLoading === 'noSubstitutesClick'}
                disabled={updateProductsInProgress}
                icon={<ClearIcon />}
                shape="round"
                onClick={() => handleCancel(true)}
              >
                Cancel
              </Button>
              <Button
                className="flex justify-center items-center"
                disabled={updateProductsInProgress || isClearList}
                type="primary"
                shape="round"
                icon={<DeleteIcon />}
                onClick={handleClearListClick}
              >
                Clear List
              </Button>
              <Button
                className="flex justify-center items-center"
                loading={updateProductsInProgress}
                disabled={
                  // addSubstitute.length + removeSubstitute.length < 1 ||
                  // currentDefaultSubstituteStatus === defaultSubstituteCheckbox ||
                  // isClearList ||
                  updateProductsInProgress
                }
                type="primary"
                shape="round"
                icon={<TaskIcon />}
                onClick={handleConfirmSubstitutesClick}
              >
                Confirm Substitutes
              </Button>
            </Space>
          </div>
        </div>
      </Modal>
    </>
  );
};
