import { Switch } from 'antd';
import { CustomCellRendererProps } from 'ag-grid-react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ProductActions } from '../../../redux/actions';
import { IProduct } from '../../../types';

export const CustomCellBopisAtStore = (props: CustomCellRendererProps<IProduct>) => {
  const dispatch = useDispatch();
  const handleChange = () => {
    if (props.data?.id && props.data.bopisAtStore) {
      const currentStore = props.data.bopisAtStore.store;
      if (props.value) {
        props.setValue?.(false);
        dispatch(
          ProductActions.updateProducts.saga({
            actionType: 'setRestrictedStores',
            updatePayload: {
              shopifyId: props.data?.id,
              removeLocationCode: [],
              addLocationCodes: [currentStore.locationCode],
            },
          }),
        );
      } else {
        props.setValue?.(true);
        dispatch(
          ProductActions.updateProducts.saga({
            actionType: 'setRestrictedStores',
            updatePayload: {
              shopifyId: props.data?.id,
              removeLocationCode: [currentStore.locationCode],
              addLocationCodes: [],
            },
          }),
        );
      }
    }
  };

  return <Switch checked={props.value} onClick={handleChange} />;
};
