import { ICustomTextCellProps } from '../../Table/CustomComponents';
import { ColDef, ISetFilterParams } from 'ag-grid-community';
import { IProduct } from '../../../types';
import capitalize from 'lodash/capitalize';
import { CustomCellStores } from './CustomCellStores';
import { CustomCellSubstitutes } from './CustomCellSubstitutes';
import { CustomCellBopis } from './CustomCellBopis';
import { CustomCellItemTitle } from './CustomCellItemTitle';
import { CustomCellBopisAtStore } from './CustomCellBopisAtStore';
import { RouteNames } from '../../../types';

const getProductsTableCommonColDef = (): ColDef<IProduct>[] => {
  return [
    {
      colId: 'itemCode',
      field: 'itemCode.value',
      headerName: 'Item Code',
      filter: 'agTextColumnFilter',
    },
    {
      colId: 'price',
      field: 'priceRangeV2.minVariantPrice.amount',
      headerName: 'Price',
      filter: 'agNumberColumnFilter',
      filterParams: {
        inRangeInclusive: true,
      },
      valueGetter: params => {
        return params.data?.priceRangeV2.minVariantPrice.amount !== undefined &&
          params.data?.priceRangeV2.minVariantPrice.amount !== null
          ? +params.data?.priceRangeV2.minVariantPrice.amount
          : params.data?.priceRangeV2.minVariantPrice.amount;
      },
      valueFormatter: params => {
        return `$ ${params.value}`;
      },
    },
    {
      colId: 'itemTitle',
      field: 'title',
      filter: 'agTextColumnFilter',
      headerName: 'Item Title',
      cellRenderer: CustomCellItemTitle,
    },
    {
      colId: 'itemCategory',
      field: 'productType',
      headerName: 'Item Category',
      filter: 'agTextColumnFilter',
      valueGetter: params => {
        return params.data?.productType
          ? params.data?.productType
              .split('/')
              .map((term: string) => capitalize(term))
              .join(' / ')
          : '';
      },
    },
    {
      colId: 'subs',
      field: 'subs',
      headerName: 'Subs',
      filter: 'agNumberColumnFilter',
      cellRenderer: CustomCellSubstitutes,
    },
    {
      colId: 'stores',
      headerName: '# Stores',
      filter: 'agNumberColumnFilter',
      cellRenderer: CustomCellStores,
      valueGetter: (params: any) => {
        return params.data?.stores?.length || 0;
      },
    },
    {
      colId: 'dcOh',
      headerName: 'DC OH',
      filter: 'agNumberColumnFilter',

      filterParams: {
        inRangeInclusive: true,
      },
      valueGetter: () => {
        return 9999;
      },
    },
    {
      colId: 'strOh',
      headerName: 'Str OH',
      filter: 'agNumberColumnFilter',
      valueGetter: () => {
        return 23736;
      },
    },
  ];
};

const getProductsTableColDefForSettingsProductsPage = (): ColDef<IProduct>[] => {
  return [
    {
      colId: 'bopis',
      headerName: 'BOPIS',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionCurrentPageOnly: true,
      cellRenderer: CustomCellBopis,
      filter: 'agSetColumnFilter',
      filterParams: {
        valueFormatter: params => {
          return params.value ? 'Yes' : 'No';
        },
        comparator: (a, b) => {
          if (a === 'Yes') {
            return -1;
          }
          return 1;
        },
      } satisfies ISetFilterParams,
      valueGetter: (params: any) => {
        return params.data?.bopis?.value === 'true' ? true : false;
      },

      valueSetter: (params: any) => {
        if (params.data.bopis) {
          params.data.bopis.value = params.newValue ? 'true' : 'false';
        } else {
          params.data.bopis = {};
          params.data.bopis.value = params.newValue ? 'true' : 'false';
        }

        return true;
      },
    },
    ...getProductsTableCommonColDef(),
  ];
};

const getProductsTableColDefForSettingsStoresDetailsPage = (): ColDef<IProduct>[] => {
  return [
    {
      colId: 'bopisAtStore',
      headerName: 'BOPIS At Store',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionCurrentPageOnly: true,
      cellRenderer: CustomCellBopisAtStore,
      filter: 'agSetColumnFilter',
      filterParams: {
        valueFormatter: params => {
          return params.value ? 'Yes' : 'No';
        },
        comparator: (a, b) => {
          return -1;
        },
      } satisfies ISetFilterParams,
      valueGetter: params => {
        return !!params.data?.bopisAtStore?.status;
      },
      valueSetter: params => {
        if (params.data.bopisAtStore) {
          params.data.bopisAtStore = {
            ...params.data.bopisAtStore,
            status: !!params.newValue,
          };
          return true;
        }

        return false;
      },
    },
    ...getProductsTableCommonColDef(),
  ];
};

export const ProductTableColDef = {
  [RouteNames.AdminSettingsProducts]: getProductsTableColDefForSettingsProductsPage,
  [RouteNames.AdminSettingsStoresDetails]: getProductsTableColDefForSettingsStoresDetailsPage,
};
