import { BaseDesktopHeader } from '../../Layout';
import { Menu, Button } from 'antd';
import { ChevronLeftIcon, ChevronRightIcon } from '../../Icons';
import clsx from 'clsx';
import { useState, useMemo, useEffect } from 'react';
import { getMenuItems } from './menuItems';
import { RouteNames } from '../../../types';
import { useLocation, matchPath } from 'react-router-dom';

type RouteNamesKeyType = keyof typeof RouteNames;

export const AdminLayout = (props: React.PropsWithChildren) => {
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);
  const location = useLocation();

  const currentSelectedKey = useMemo(() => {
    if (matchPath(RouteNames.AdminSettingsStoresDetails, location.pathname)) {
      return RouteNames.AdminSettingsStores;
    }
    for (const value in RouteNames) {
      if (matchPath(RouteNames[value as RouteNamesKeyType], location.pathname)) {
        return RouteNames[value as RouteNamesKeyType];
      }
    }
  }, [location.pathname]);

  const menuItems = useMemo(() => {
    return getMenuItems({
      isMenuCollapsed,
    });
  }, [isMenuCollapsed]);

  return (
    <div className="h-full flex flex-col">
      <BaseDesktopHeader />
      <div className="h-full relative">
        <div className=" absolute w-full h-full flex flex-row flex-nowrap overflow-hidden">
          <div className=" h-full border-solid border-0 border-r-2  border-colorPrimary overflow-hidden shrink-0">
            <div className=" h-[calc(100%-32px)] overflow-y-auto">
              <Menu
                inlineIndent={18}
                defaultOpenKeys={[RouteNames.AdminSettings, RouteNames.AdminAnalyze]}
                selectedKeys={currentSelectedKey ? [currentSelectedKey] : undefined}
                mode="inline"
                inlineCollapsed={false}
                items={menuItems}
                className={clsx('bg-transparent !border-0', !isMenuCollapsed && 'min-w-60')}
              />
            </div>
            <div className=" text-right">
              <Button
                block={isMenuCollapsed}
                className={clsx('h-[32px] hover:!bg-transparent leading-none')}
                type="text"
                onClick={() => setIsMenuCollapsed(prev => !prev)}
              >
                {isMenuCollapsed ? <ChevronRightIcon size={20} /> : <ChevronLeftIcon size={20} />}
              </Button>
            </div>
          </div>
          <div className="overflow-auto w-full">{props.children}</div>
        </div>
      </div>
    </div>
  );
};
