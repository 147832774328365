import { createReducer } from '@reduxjs/toolkit';
import { InitialAsyncState, asyncCaseCreator } from '../../redux/helper';
import { EReduxAsyncState } from '../../types/redux';
import { AuthActions, CommonActions } from '../../redux/actions';
import { IMongodbUser } from '../../types/mongodb/TypeUser';
import * as Realm from 'realm-web';

interface IAuthState {
  validateUser: InitialAsyncState;
  loginAccount: InitialAsyncState;
  logoutAccount: InitialAsyncState;
  currentUser?: IMongodbUser;
}

const defaultAuthState: IAuthState = {
  validateUser: { status: EReduxAsyncState.inProgress },
  loginAccount: { status: EReduxAsyncState.idle },
  logoutAccount: { status: EReduxAsyncState.idle },
};

export const authReducer = createReducer(defaultAuthState, builder => {
  asyncCaseCreator(AuthActions.validateUser, 'validateUser', builder);
  asyncCaseCreator(AuthActions.logoutAccount, 'logoutAccount', builder);
  asyncCaseCreator(AuthActions.loginAccount, 'loginAccount', builder);

  builder.addCase(AuthActions.setCurrentUser, (state, action) => {
    state.currentUser = Realm.BSON.EJSON.parse(
      JSON.stringify(action.payload),
    ) as typeof action.payload;
  });

  builder.addCase(CommonActions.clearReduxStore, (state, action) => {
    return defaultAuthState;
  });
});
