import { call, fork, put, takeEvery, take, takeLatest } from 'redux-saga/effects';
import { OrdersActions } from '../actions';
import { getOrdersForStore, mongoDBWatchEmitter } from '../services/orderService';
import { ActionType } from 'typesafe-actions';
import { IMongodbOrder } from '../../types/mongodb';
import { IShopifyOrder } from '../../types/shopify/TypeOrder';
import { convertArrayToMap } from '../../utils/common';

export function* getOrdersForStoreSaga(
  action: ActionType<typeof OrdersActions.getOrdersForStore.saga>,
): Generator<unknown, void, unknown> {
  try {
    const { id } = action.payload;
    yield put(OrdersActions.getOrdersForStore.start());
    const orders: any = yield call(getOrdersForStore, id);
    const { mongodbOrders, shopifyOrders } = orders as {
      shopifyOrders: IShopifyOrder[];
      mongodbOrders: IMongodbOrder[];
    };
    const shopifyOrdersMap = convertArrayToMap(shopifyOrders, 'legacyResourceId');
    const mongodbOrdersMap = convertArrayToMap(mongodbOrders, '_id');
    yield put(OrdersActions.setShopifyOrders(shopifyOrdersMap));
    yield put(OrdersActions.setMongodbOrders(mongodbOrdersMap));
    yield put(OrdersActions.getOrdersForStore.success());
  } catch (error: unknown) {
    console.error('orderSagaError', error);
    OrdersActions.getOrdersForStore.error(error as Error);
  }
}

export function* getOrdersCountSaga(): Generator<unknown, void, unknown> {
  const chan: any = yield call(mongoDBWatchEmitter);
  try {
    while (true) {
      const changed: any = yield take(chan);

      const { documentKey, fullDocument, operationType } = changed;
      if (operationType === 'update') {
        yield put(OrdersActions.updateMongodbOrder({ id: documentKey._id, order: fullDocument }));
      }
    }
  } catch (err) {
    throw err;
  } finally {
    console.log('countdown terminated');
  }
}

function* getOrdersForStoreListener() {
  yield takeEvery(OrdersActions.getOrdersForStore.saga.toString(), getOrdersForStoreSaga);
}
function* getOrdersCountListener() {
  yield takeLatest(OrdersActions.getOrdersCount.saga.toString(), getOrdersCountSaga);
}

export default function* orderSaga() {
  yield fork(getOrdersForStoreListener);
  yield fork(getOrdersCountListener);
}
