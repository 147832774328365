import { createReducer } from '@reduxjs/toolkit';
import { InitialAsyncState, asyncCaseCreator } from '../helper';
import { EReduxAsyncState } from '../../types/redux';
import { OrdersActions } from '../actions';
import { IMongodbOrder } from '../../types/mongodb';
import { IShopifyOrder } from '../../types/shopify/TypeOrder';
interface IOrderState {
  getOrdersForStore: InitialAsyncState;
  getOrdersCount: InitialAsyncState;
  mongodbOrders: Map<string, IMongodbOrder>;
  shopifyOrders: Map<string, IShopifyOrder>;
}

const defaultOrderState: IOrderState = {
  getOrdersForStore: { status: EReduxAsyncState.idle },
  getOrdersCount: { status: EReduxAsyncState.idle },
  mongodbOrders: new Map(),
  shopifyOrders: new Map(),
};

export const orderReducer = createReducer(defaultOrderState, builder => {
  asyncCaseCreator(OrdersActions.getOrdersForStore, 'getOrdersForStore', builder);
  asyncCaseCreator(OrdersActions.getOrdersCount, 'getOrdersCount', builder);

  builder.addCase(OrdersActions.setShopifyOrders, (state, action) => {
    state.shopifyOrders = new Map(action.payload);
  });
  builder.addCase(OrdersActions.setMongodbOrders, (state, action) => {
    state.mongodbOrders = new Map(action.payload);
  });
  builder.addCase(OrdersActions.updateMongodbOrder, (state, action) => {
    const { id, order } = action.payload;
    state.mongodbOrders.set(id, order);
  });
});
